import ObjectUtil from "./util/ObjectUtil"

export class HackleCoreContext {
  private static instances = new Map<string, any>()

  static get<T>(name: string): T {
    return ObjectUtil.requiredNotNullOrUndefined(this.getOrNull(name), () => `Instance not registered [${name}]`)
  }

  static getOrNull<T>(name: string): T | null {
    return HackleCoreContext.instances.get(name) as T
  }

  static register<T>(name: string, instance: T): T {
    HackleCoreContext.instances.set(name, instance)
    return instance
  }
}
