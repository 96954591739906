import Workspace from "../../../core/internal/workspace/Workspace"
import {
  HackleUser,
  InAppMessage,
  InAppMessageDurationCap,
  InAppMessageEventFrequencyCap,
  InAppMessageEventTriggerRule,
  InAppMessageIdentifierCap
} from "../../../core/internal/model/model"
import UserEvent, { TrackEvent } from "../../../core/internal/event/UserEvent"
import TargetMatcher from "../../../core/internal/evaluation/match/TargetMatcher"
import {
  EvaluatorContext,
  EvaluatorKey,
  EventEvaluatorRequest
} from "../../../core/internal/evaluation/evalautor/Evaluator"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"
import { InAppMessageImpression, InAppMessageImpressionStorage } from "../storage/InAppMessageImpressionStorage"

export interface InAppMessageEventTriggerDeterminer {
  isTriggerTarget(workspace: Workspace, inAppMessage: InAppMessage, event: TrackEvent): boolean
}

export class InAppMessageEventTriggerRuleDeterminer implements InAppMessageEventTriggerDeterminer {
  constructor(private targetMatcher: TargetMatcher) {}

  public isTriggerTarget(workspace: Workspace, inAppMessage: InAppMessage, event: TrackEvent): boolean {
    return inAppMessage.eventTrigger.rules.some((it) => this.ruleMatches(workspace, event, inAppMessage, it))
  }

  private ruleMatches(
    workspace: Workspace,
    event: TrackEvent,
    inAppMessage: InAppMessage,
    rule: InAppMessageEventTriggerRule
  ): boolean {
    if (event.event.key !== rule.eventKey) {
      return false
    }
    const request = EvaluatorRequest.of(workspace, event, inAppMessage)
    return this.targetMatcher.anyMatches(request, EvaluatorContext.create(), rule.targets)
  }
}

class EvaluatorRequest implements EventEvaluatorRequest {
  private constructor(
    public readonly key: EvaluatorKey,
    public readonly workspace: Workspace,
    public readonly user: HackleUser,
    public readonly event: UserEvent
  ) {}

  static of(workspace: Workspace, event: UserEvent, inAppMessage: InAppMessage): EvaluatorRequest {
    return new EvaluatorRequest(new EvaluatorKey("IN_APP_MESSAGE", inAppMessage.id), workspace, event.user, event)
  }
}

export class InAppMessageEventTriggerFrequencyCapDeterminer implements InAppMessageEventTriggerDeterminer {
  constructor(private storage: InAppMessageImpressionStorage) {}

  public isTriggerTarget(workspace: Workspace, inAppMessage: InAppMessage, event: TrackEvent): boolean {
    if (!inAppMessage.eventTrigger.frequencyCap) {
      return true
    }

    const contexts = this.createMatchContexts(inAppMessage.eventTrigger.frequencyCap)
    if (contexts.length === 0) {
      return true
    }

    const impressions = this.storage.get(inAppMessage)
    for (const impression of impressions) {
      for (const context of contexts) {
        if (context.matches(event, impression)) {
          return false
        }
      }
    }

    return true
  }

  private createMatchContexts(frequencyCap: InAppMessageEventFrequencyCap): MatchContext[] {
    const contexts: MatchContext[] = []

    for (const identifierCap of frequencyCap.identifierCaps) {
      contexts.push(new MatchContext(new IdentifierCapPredicate(identifierCap)))
    }

    if (frequencyCap.durationCap) {
      contexts.push(new MatchContext(new DurationCapPredicate(frequencyCap.durationCap)))
    }

    return contexts
  }
}

class MatchContext {
  private readonly predicate: FrequencyCapPredicate
  private matchCount = 0

  constructor(predicate: FrequencyCapPredicate) {
    this.predicate = predicate
  }

  public matches(event: UserEvent, impression: InAppMessageImpression): boolean {
    if (this.predicate.matches(event, impression)) {
      this.matchCount += 1
    }
    return this.matchCount >= this.predicate.thresholdCount
  }
}

interface FrequencyCapPredicate {
  thresholdCount: number

  matches(event: UserEvent, impression: InAppMessageImpression): boolean
}

export class IdentifierCapPredicate implements FrequencyCapPredicate {
  constructor(private readonly identifierCap: InAppMessageIdentifierCap) {}

  get thresholdCount(): number {
    return this.identifierCap.count
  }

  matches(event: UserEvent, impression: InAppMessageImpression): boolean {
    const userIdentifier = event.user.identifiers[this.identifierCap.identifierType]
    if (ObjectUtil.isNullOrUndefined(userIdentifier)) return false

    const impressionIdentifier = impression.identifiers[this.identifierCap.identifierType]
    if (ObjectUtil.isNullOrUndefined(impressionIdentifier)) return false

    return userIdentifier === impressionIdentifier
  }
}

export class DurationCapPredicate implements FrequencyCapPredicate {
  constructor(private readonly durationCap: InAppMessageDurationCap) {}

  get thresholdCount(): number {
    return this.durationCap.count
  }

  matches(event: UserEvent, impression: InAppMessageImpression): boolean {
    return event.timestamp - impression.timestamp <= this.durationCap.durationMillis
  }
}
