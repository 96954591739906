import { Identifier, Identifiers } from "../model/model"
import ObjectUtil from "./ObjectUtil"
import Logger from "../logger"

const log = Logger.log

export default class IdentifierUtil {
  private static MAX_IDENTIFIER_TYPE_LENGTH = 128
  private static MAX_IDENTIFIER_VALUE_LENGTH = 512

  static isEqual(a: Identifier, b: Identifier): boolean {
    return a.type === b.type && a.value === b.value
  }

  static isEquals(identifiers: Identifiers, other: Identifiers | undefined): boolean {
    if (other === undefined || other === null) {
      return false
    }

    const identifierTypes = Object.keys(identifiers)

    if (identifierTypes.length !== Object.keys(other).length) {
      return false
    }

    return identifierTypes.every((it) => identifiers[it] === other[it])
  }

  static sanitize(identifiers: { [key: string]: any } | null | undefined): Identifiers {
    const sanitized: Identifiers = {}

    if (ObjectUtil.isNullOrUndefined(identifiers)) {
      return sanitized
    }

    for (let [type, value] of Object.entries(identifiers)) {
      if (!this.isValidType(type)) {
        log.debug(`Invalid identifier type. The identifier type must be a string of 128 characters or less. [${type}]`)
        continue
      }

      const sanitizedValue = this.sanitizeValue(value)
      if (ObjectUtil.isNullOrUndefined(sanitizedValue)) {
        log.debug(
          `Invalid identifier value. The identifier type value be a string of 512 characters or less. [${type}]`
        )
        continue
      }

      sanitized[type] = sanitizedValue
    }
    return sanitized
  }

  static isValidType(type: any): type is string {
    if (ObjectUtil.isNullOrUndefined(type)) {
      return false
    }

    return typeof type === "string" && type.length > 0 && type.length <= this.MAX_IDENTIFIER_TYPE_LENGTH
  }

  static sanitizeValue(identifierValue: any): string | null {
    if (
      typeof identifierValue === "string" &&
      identifierValue.length > 0 &&
      identifierValue.length <= this.MAX_IDENTIFIER_VALUE_LENGTH
    ) {
      return identifierValue
    }

    if (typeof identifierValue === "number") {
      return identifierValue.toString()
    }

    return null
  }
}
