import ObjectUtil from "../../core/internal/util/ObjectUtil"
import { CookieStorage, CookieStorageOptions } from "../storage/CookieStorage.browser"

export class SplitUrlRedirectedCookieStorage {
  constructor(
    private readonly storage: CookieStorage,
    private readonly cookieKey: string,
    private readonly cookieStorageOptions?: CookieStorageOptions
  ) {}

  alreadyRedirected(): boolean {
    try {
      const value = this.storage.getItem(this.cookieKey)
      if (ObjectUtil.isNullOrUndefined(value)) return false
      return Boolean(JSON.parse(value))
    } catch (e) {
      return false
    }
  }

  clear(): void {
    this.storage.removeItem(this.cookieKey)
  }

  setRedirected(redirected: boolean): void {
    this.storage.setItem(this.cookieKey, String(redirected), this.cookieStorageOptions)
  }
}
