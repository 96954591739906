import { HackleRemoteConfig, User } from "../../core/internal/model/model"
import Logger from "../../core/internal/logger"
import {
  HackleAppInvocationProcessor,
  RemoteConfigInvocation,
  RemoteConfigInvocationDto
} from "../invocator/HackleAppInvocationProcessor"

const log = Logger.log

export default class HackleWebAppRemoteConfig implements HackleRemoteConfig {
  constructor(
    private readonly invocationProcessor: HackleAppInvocationProcessor,
    private readonly user?: string | User
  ) {}

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    const valueType = typeof defaultValue

    try {
      const remoteConfigInvocation: RemoteConfigInvocation = {
        command: "remoteConfig",
        parameters: {
          defaultValue,
          key,
          valueType,
          user: this.user
        }
      }
      const result = this.invocationProcessor.process<RemoteConfigInvocationDto, typeof defaultValue>(
        remoteConfigInvocation
      )

      if (!result) {
        throw new Error("invoke result data not exists")
      }

      switch (valueType) {
        case "number":
          return Number(result)
        case "boolean":
          return Boolean(result)
        default:
          return result
      }
    } catch (e) {
      log.error(`Unexpected exception while deciding remote config parameter[${key}]. Returning default value. : ${e}`)
      return defaultValue
    }
  }
}
