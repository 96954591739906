export default class BrowserInitializeDoneEventDispatcher {
  needDispatch: boolean = true

  private static readonly HACKLE_INITIALIZE_DONE_EVENT_TYPE = "hackle-initialize-done"

  setNeedDispatch(needDispatch: boolean) {
    this.needDispatch = needDispatch
  }

  dispatchDoneEventIfNeeded() {
    if (this.needDispatch) {
      window.dispatchEvent(new CustomEvent(BrowserInitializeDoneEventDispatcher.HACKLE_INITIALIZE_DONE_EVENT_TYPE))
    }
  }
}
