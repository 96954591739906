import Workspace from "../../core/internal/workspace/Workspace"
import { Decision, Experiment } from "../../core/internal/model/model"
import { TrackEvent } from "../../core/internal/event/UserEvent"

export class SplitUrlContext {
  constructor(
    readonly workspace: Workspace,
    readonly experiment: Experiment,
    readonly decision: Decision,
    readonly event: TrackEvent
  ) {}
}
