import UserEvent, { ExposureEvent } from "../../core/internal/event/UserEvent"
import WorkspaceFetcher from "../../core/internal/workspace/WorkspaceFetcher"
import { DecisionReason } from "../../core/internal/model/model"

export default class GoogleAnalyticsDeterminer {
  constructor(private readonly workspaceFetcher: WorkspaceFetcher) {}

  public determineTargetExposureOrNull(event: UserEvent): ExposureEvent | null {
    const workspace = this.workspaceFetcher.get()
    if (!workspace) return null
    const attributes = workspace.getAttributes()
    if (!attributes.integration_ga_experiment) return null
    if (!UserEvent.isExposureEvent(event)) return null
    if (event.experiment.status !== "RUNNING") return null
    if (event.decisionReason !== DecisionReason.TRAFFIC_ALLOCATED) return null

    return event
  }
}
