import UAParser from "./UAParser"
import { Properties } from "../../core/internal/model/model"

type WindowLocation = {
  hostname: string
  pathname: string
  host: string
  protocol: string
  search: string
}
type WindowDocument = {
  location: WindowLocation
  title: string
  referrer: string
}
type WindowNavigator = {
  userAgent: string
  vendor?: string
  languages?: Array<string>
}
type WindowScreen = {
  width: number
  height: number
}
type Window = {
  navigator: WindowNavigator
  document: WindowDocument
  location: WindowLocation
  screen: WindowScreen
}

export default class HacklePropertyGenerator {
  static generate(global?: any): Properties {
    let win
    // Server-Side Ignore
    if (typeof global === "undefined") {
      const loc = {
        hostname: "",
        pathname: "",
        host: "",
        search: "",
        protocol: ""
      }
      win = {
        navigator: { userAgent: "", vendor: "", languages: [] },
        document: {
          location: loc,
          title: ""
        },
        location: loc,
        screen: {
          width: "",
          height: ""
        }
      }
    } else {
      win = global
    }

    const result = UAParser.parse(win.navigator.userAgent)

    const browser = result.browser
    const os = result.os
    const device = result.device
    const _protocol = win.location.protocol || ""
    const protocol = _protocol.replace(":", "")
    const screen = win.screen
    const locale = win.navigator.languages?.[0] || ""
    const language = locale.split("-")[0]
    const deviceType = (device.type ?? "pc").replace(/\b(mobile|smarttv)\b/g, (match, p1) => {
      if (p1 === "mobile") return "phone"
      if (p1 === "smarttv") return "tv"
      return match
    })
    let osName = os.name || ""
    let orientation = ""
    let timezone = ""
    try {
      osName = osName.match(/\b(Mac|Windows|Linux|Android|iOS)\b/)?.[0] ?? osName
      orientation = screen.orientation.type.match(/\b(landscape|portrait)\b/)?.[0] ?? ""
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || ""
    } catch (e) {}

    return {
      platform: "Web",
      browserName: browser.name || "",
      browserMajorVersion: browser.major || "",
      browserVersion: browser.version || "",
      osName: osName,
      osVersion: os.version || "",
      deviceModel: device.model || "",
      deviceType: deviceType,
      deviceVendor: device.vendor || "", // @Deprecated. Use deviceBrand instead
      deviceBrand: device.vendor || "",
      locale: locale,
      language: language,
      orientation: orientation,
      userAgent: win.navigator.userAgent || "",
      pagePath: win.location.pathname || "",
      pageTitle: win.document.title || "",
      host: win.location.host || "",
      url: win.location.href || "",
      protocol: protocol,
      referrer: win.document.referrer || "",
      queryParameter: win.location.search || "",
      screenWidth: screen.width || "",
      screenHeight: screen.height || "",
      timeZone: timezone
    }
  }
}
