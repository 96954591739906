import { CachedUserEventDeterminer } from "./CachedUserEventDeterminer"
import UserEvent, { RemoteConfigEvent } from "../../../core/internal/event/UserEvent"
import { Clock, SystemClock } from "../../../core/internal/util/TimeUtil"

export class RemoteConfigEventDedupDeterminer extends CachedUserEventDeterminer<RemoteConfigEvent> {
  constructor(dedupIntervalMillis: number, clock: Clock = SystemClock.instance) {
    super(dedupIntervalMillis, clock)
  }

  supports(event: UserEvent): event is RemoteConfigEvent {
    return UserEvent.isRemoteConfigEvent(event)
  }

  cacheKey(event: RemoteConfigEvent): string {
    return [event.parameter.id, event.valueId, event.decisionReason].join("-")
  }
}
