import HackleCore from "../../HackleCore"
import { HackleUserManualOverrideStorage } from "../evaluation/target/ManualOverrideStorage"
import { Metrics } from "../metrics/Metrics"
import { Decision, Experiment, ExperimentType, FeatureFlagDecision, HackleUser, List, Long } from "../model/model"
import { UserManager } from "./UserManager"

export interface HackleUserExplorerBase {
  currentUser(): HackleUser

  getAbTestDecisions(): List<[Experiment, Decision]>

  getAbTestOverrides(): Map<Long, Long>

  setAbTestOverride(experiment: Experiment, variationId: Long): void

  resetAbTestOverride(experiment: Experiment): void

  resetAllAbTestOverride(): void

  getFeatureFlagDecisions(): List<[Experiment, FeatureFlagDecision]>

  getFeatureFlagOverrides(): Map<Long, Long>

  setFeatureFlagOverride(experiment: Experiment, variationId: Long): void

  resetFeatureFlagOverride(experiment: Experiment): void

  resetAllFeatureFlagOverride(): void
}

export class HackleUserExplorerImpl implements HackleUserExplorerBase {
  constructor(
    private core: HackleCore,
    private userManager: UserManager,
    private abOverrideStorage: HackleUserManualOverrideStorage,
    private ffOverrideStorage: HackleUserManualOverrideStorage
  ) {}

  currentUser(): HackleUser {
    return this.userManager.resolve()
  }

  getAbTestDecisions(): List<[Experiment, Decision]> {
    return this.core.experiments(this.currentUser()).entries()
  }

  getAbTestOverrides(): Map<number, number> {
    return this.abOverrideStorage.getAll()
  }

  setAbTestOverride(experiment: Experiment, variationId: number): void {
    this.abOverrideStorage.set(experiment, variationId)
    this.increment("AB_TEST", "set")
  }

  resetAbTestOverride(experiment: Experiment): void {
    this.abOverrideStorage.remove(experiment)
    this.increment("AB_TEST", "reset")
  }

  resetAllAbTestOverride(): void {
    this.abOverrideStorage.clear()
    this.increment("AB_TEST", "reset.all")
  }

  getFeatureFlagDecisions(): List<[Experiment, FeatureFlagDecision]> {
    return this.core.featureFlags(this.currentUser()).entries()
  }

  getFeatureFlagOverrides(): Map<number, number> {
    return this.ffOverrideStorage.getAll()
  }

  setFeatureFlagOverride(experiment: Experiment, variationId: number): void {
    this.ffOverrideStorage.set(experiment, variationId)
    this.increment("FEATURE_FLAG", "set")
  }

  resetFeatureFlagOverride(experiment: Experiment): void {
    this.ffOverrideStorage.remove(experiment)
    this.increment("FEATURE_FLAG", "reset")
  }

  resetAllFeatureFlagOverride(): void {
    this.ffOverrideStorage.clear()
    this.increment("FEATURE_FLAG", "reset.all")
  }

  private increment(experimentType: ExperimentType, operation: string) {
    Metrics.counter("experiment.manual.override", {
      "experiment.type": experimentType,
      operation
    }).increment()
  }
}
