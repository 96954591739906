import EvaluationFlow from "../../flow/EvaluationFlow"
import { InAppMessageRequest } from "./InAppMessageRequest"
import { InAppMessageEvaluation } from "./InAppMessageEvaluation"
import FlowEvaluator from "../../flow/FlowEvaluator"
import { EvaluatorContext } from "../Evaluator"
import { DecisionReason } from "../../../model/model"
import { InAppMessageMatcher, InAppMessageResolver } from "../../target/InAppMessageResolver"

export type InAppMessageFlow = EvaluationFlow<InAppMessageRequest, InAppMessageEvaluation>

export default interface InAppMessageFlowEvaluator extends FlowEvaluator<InAppMessageRequest, InAppMessageEvaluation> {
  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined
}

export class PlatformInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (!request.inAppMessage.supports("WEB")) {
      return InAppMessageEvaluation.of(request, context, DecisionReason.UNSUPPORTED_PLATFORM)
    }
    return nextFlow.evaluate(request, context)
  }
}

export class OverrideInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  constructor(
    private readonly userOverrideMatcher: InAppMessageMatcher,
    private readonly inAppMessageResolver: InAppMessageResolver
  ) {}

  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (this.userOverrideMatcher.matches(request, context)) {
      const message = this.inAppMessageResolver.resolve(request, context)
      return InAppMessageEvaluation.of(request, context, DecisionReason.OVERRIDDEN, message)
    }
    return nextFlow.evaluate(request, context)
  }
}

export class DraftInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (request.inAppMessage.status === "DRAFT") {
      return InAppMessageEvaluation.of(request, context, DecisionReason.IN_APP_MESSAGE_DRAFT)
    }
    return nextFlow.evaluate(request, context)
  }
}

export class PauseInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (request.inAppMessage.status === "PAUSE") {
      return InAppMessageEvaluation.of(request, context, DecisionReason.IN_APP_MESSAGE_PAUSED)
    }
    return nextFlow.evaluate(request, context)
  }
}

export class PeriodInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (!request.inAppMessage.period.within(request.timestamp)) {
      return InAppMessageEvaluation.of(request, context, DecisionReason.NOT_IN_IN_APP_MESSAGE_PERIOD)
    }
    return nextFlow.evaluate(request, context)
  }
}

export class HiddenInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  constructor(private readonly hiddenMatcher: InAppMessageMatcher) {}

  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (this.hiddenMatcher.matches(request, context)) {
      return InAppMessageEvaluation.of(request, context, DecisionReason.IN_APP_MESSAGE_HIDDEN)
    }
    return nextFlow.evaluate(request, context)
  }
}

export class TargetInAppMessageFlowEvaluator implements InAppMessageFlowEvaluator {
  constructor(
    private readonly targetMatcher: InAppMessageMatcher,
    private readonly inAppMessageResolver: InAppMessageResolver
  ) {}

  evaluate(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    nextFlow: InAppMessageFlow
  ): InAppMessageEvaluation | undefined {
    if (this.targetMatcher.matches(request, context)) {
      const message = this.inAppMessageResolver.resolve(request, context)
      return InAppMessageEvaluation.of(request, context, DecisionReason.IN_APP_MESSAGE_TARGET, message)
    }
    return InAppMessageEvaluation.of(request, context, DecisionReason.NOT_IN_IN_APP_MESSAGE_TARGET)
  }
}
