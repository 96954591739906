import { Transport, TransportRequest, TransportResponse } from "../transport/Transport"
import { EventDto, toPayload } from "./dto"
import { TimerSample } from "../metrics/Timer"
import { ApiCallMetrics } from "../metrics/monitoring/MonitoringMetricRegistry"

export class EventTransport {
  constructor(private readonly url: string, private readonly transport: Transport) {}

  send(events: EventDto[]): Promise<TransportResponse> {
    const request = this.createRequest(events)
    return this.execute(request)
  }

  private createRequest(events: EventDto[]): TransportRequest {
    const payload = toPayload(events)
    return TransportRequest.builder()
      .url(this.url)
      .method("POST")
      .addHeader("Content-Type", "application/json")
      .body(JSON.stringify(payload))
      .build()
  }

  private async execute(request: TransportRequest): Promise<TransportResponse> {
    const sample = TimerSample.start()
    try {
      const response = await this.transport.send(request)
      ApiCallMetrics.record("post.events", sample, response)
      return response
    } catch (e) {
      ApiCallMetrics.record("post.events", sample, null)
      throw e
    }
  }
}
