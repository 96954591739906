import { Sdk, User } from "../../core/internal/model/model"
import { DevTools } from "../index.browser"
import { Config } from "../../index.browser"
import {
  BROWSER_MIN_POOL_INTERVAL,
  DEFAULT_CDN_URL,
  DEFAULT_CLIENT_SDK_URL,
  DEFAULT_DEDUP_INTERVAL_MILLIS,
  DEFAULT_EVENT_URL,
  DEFAULT_MONITORING_URL,
  DEFAULT_SESSION_TIMEOUT_MILLIS,
  IAM_RENDERER_VERSION,
  NO_POLLING,
  SDK_VERSION
} from "../../config"
import Logger, { logLevels } from "../../core/internal/logger"
import { Metrics } from "../../core/internal/metrics/Metrics"

export interface InternalConfig {
  user?: User

  debug: boolean
  log_disabled: boolean
  auto_track_page_view: boolean
  pollingIntervalMillis: number
  exposureEventDedupIntervalMillis: number
  sessionTimeoutMillis: number
  devTool?: DevTools["manager"]
  autoOpenDevTool?: boolean

  sdkUrl: string
  eventUrl: string
  monitoringUrl: string
  cdnUrl: string
  sameSiteCookie: string
  secureCookie: boolean

  SDK_NAME_HEADER: string
  SDK_VERSION_HEADER: string

  IAM_RENDERER_VERSION_HEADER: string
}

const DEFAULT_CONFIG: InternalConfig = {
  user: undefined,

  debug: false,
  log_disabled: false,
  auto_track_page_view: true,
  pollingIntervalMillis: NO_POLLING,
  exposureEventDedupIntervalMillis: DEFAULT_DEDUP_INTERVAL_MILLIS,
  sessionTimeoutMillis: DEFAULT_SESSION_TIMEOUT_MILLIS,
  autoOpenDevTool: false,

  sdkUrl: DEFAULT_CLIENT_SDK_URL,
  eventUrl: DEFAULT_EVENT_URL,
  monitoringUrl: DEFAULT_MONITORING_URL,
  cdnUrl: DEFAULT_CDN_URL,
  sameSiteCookie: "LAX",
  secureCookie: false,

  SDK_NAME_HEADER: "javascript-sdk_browser",
  SDK_VERSION_HEADER: SDK_VERSION,
  IAM_RENDERER_VERSION_HEADER: IAM_RENDERER_VERSION
}

export class Configurations {
  static config = (config?: Config): InternalConfig => {
    return {
      ...DEFAULT_CONFIG,
      ...config
    }
  }

  static logging = (config: InternalConfig) => {
    Logger.initCounter((level) => Metrics.counter("log", { level: level }))

    Logger.setLogLevel(logLevels.INFO)
    if (config.log_disabled) {
      Logger.setLogLevel(logLevels.DISABLE)
    } else {
      if (config.debug) {
        Logger.setLogLevel(logLevels.DEBUG)
      }
    }
  }

  static sdk = (sdkKey: string, config: InternalConfig): Sdk => {
    Logger.log.debug(`SDK key: ${sdkKey}`)
    if (!sdkKey) {
      Logger.log.error(`invalid sdk key: '${sdkKey}' (expected: not null, not undefined and not empty string)`)
    }
    return {
      key: sdkKey,
      name: config.SDK_NAME_HEADER,
      version: config.SDK_VERSION_HEADER
    }
  }

  static isBeaconSupported = (): boolean => {
    let useBeacon = false
    if (typeof window !== "undefined") {
      useBeacon = window && window.navigator && !!window.navigator.sendBeacon && true
      if (useBeacon) {
        Logger.log.debug("support sendBeacon API")
      }
    }
    return useBeacon
  }

  static pollingIntervalMillis = (config: InternalConfig): number => {
    if (config.pollingIntervalMillis === undefined) {
      return NO_POLLING
    }

    // noinspection SuspiciousTypeOfGuard
    if (typeof config.pollingIntervalMillis !== "number") {
      return NO_POLLING
    }

    if (config.pollingIntervalMillis <= 0) {
      return NO_POLLING
    }

    return Math.max(config.pollingIntervalMillis, BROWSER_MIN_POOL_INTERVAL)
  }

  static dedupIntervalMillis = (config: InternalConfig): number => {
    if (config.exposureEventDedupIntervalMillis === undefined) {
      return DEFAULT_DEDUP_INTERVAL_MILLIS
    }

    // noinspection SuspiciousTypeOfGuard
    if (typeof config.exposureEventDedupIntervalMillis !== "number") {
      return DEFAULT_DEDUP_INTERVAL_MILLIS
    }

    if (config.exposureEventDedupIntervalMillis < 1000 || config.exposureEventDedupIntervalMillis > 1000 * 60 * 60) {
      Logger.log.warn(
        "Exposure event dedup interval is outside allowed range[1_000ms..3_600_000ms]. Setting to default value[1m]."
      )
      return DEFAULT_DEDUP_INTERVAL_MILLIS
    }

    return config.exposureEventDedupIntervalMillis
  }
}
