import { Campaign } from "./Campaign"
import { IStorage } from "../../core/internal/storage/Storage"
import { MARKETING_STORAGE_KEY } from "../../config"

export class CampaignStorage {
  constructor(private readonly storage: IStorage) {}

  get(): Campaign | null {
    const value = this.storage.getItem(MARKETING_STORAGE_KEY)
    if (!value) return null
    return JSON.parse(value)
  }

  set(campaign: Campaign): void {
    this.storage.setItem(MARKETING_STORAGE_KEY, JSON.stringify(campaign))
  }
}
