import { EvaluatorContext, EvaluatorEvaluation } from "../Evaluator"
import { HackleValue, Long, Properties, RemoteConfigParameter } from "../../../model/model"
import RemoteConfigRequest from "./RemoteConfigRequest"
import PropertyUtil from "../../../util/PropertyUtil"

export default class RemoteConfigEvaluation implements EvaluatorEvaluation {
  readonly reason: string
  readonly targetEvaluations: EvaluatorEvaluation[]
  readonly parameter: RemoteConfigParameter
  readonly valueId: Long | undefined
  readonly value: HackleValue
  readonly properties: Properties

  constructor(
    reason: string,
    targetEvaluations: EvaluatorEvaluation[],
    parameter: RemoteConfigParameter,
    valueId: Long | undefined,
    value: HackleValue,
    properties: Properties
  ) {
    this.reason = reason
    this.targetEvaluations = targetEvaluations
    this.parameter = parameter
    this.valueId = valueId
    this.value = value
    this.properties = properties
  }

  static of(
    request: RemoteConfigRequest,
    context: EvaluatorContext,
    valueId: Long | undefined,
    value: HackleValue,
    reason: string,
    properties: Properties
  ): RemoteConfigEvaluation {
    properties["returnValue"] = PropertyUtil.truncateStringValue(value)
    return new RemoteConfigEvaluation(reason, context.targetEvaluations, request.parameter, valueId, value, properties)
  }

  static ofDefault(
    request: RemoteConfigRequest,
    context: EvaluatorContext,
    reason: string,
    properties: Properties
  ): RemoteConfigEvaluation {
    return this.of(request, context, undefined, request.defaultValue, reason, properties)
  }
}
