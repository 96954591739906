import InAppMessageHiddenStorage from "../../../core/internal/evaluation/target/InAppMessageHiddenStorage"
import { InAppMessage } from "../../../core/internal/model/model"
import { IStorage } from "../../../core/internal/storage/Storage"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"

export class InAppMessageHiddenLocalStorage implements InAppMessageHiddenStorage {
  constructor(private readonly storage: IStorage) {}

  exist(inAppMessage: InAppMessage, now: number): boolean {
    const key = this.key(inAppMessage)
    const value = this.storage.getItem(key)
    if (ObjectUtil.isNullOrUndefined(value)) {
      return false
    }

    const expireAt = Number.parseInt(value, 10)
    if (Number.isNaN(expireAt)) {
      this.storage.removeItem(key)
      return false
    }

    if (now <= expireAt) {
      return true
    } else {
      this.storage.removeItem(key)
      return false
    }
  }

  put(inAppMessage: InAppMessage, expireAt: number): void {
    this.storage.setItem(this.key(inAppMessage), `${expireAt}`)
  }

  private key(inAppMessage: InAppMessage): string {
    return `${inAppMessage.key}`
  }
}
