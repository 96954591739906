import { UserEventEmitter } from "../../core/internal/event/UserEventEmitter"
import UserEvent, { ExposureEvent } from "../../core/internal/event/UserEvent"
import GoogleAnalyticsDeterminer from "./GoogleAnalyticsDeterminer"
import Logger from "../../core/internal/logger"

export default class GoogleAnalyticsManager {
  constructor(
    private readonly userEventEmitter: UserEventEmitter,
    private readonly determiner: GoogleAnalyticsDeterminer
  ) {
    this.initialize()
  }
  private initialize() {
    this.userEventEmitter.on("event", this.onEvent)
  }

  private onEvent = (event: UserEvent) => {
    const exposure = this.determiner.determineTargetExposureOrNull(event)
    if (!exposure) return

    this.send(exposure)
  }

  private send(exposure: ExposureEvent) {
    const experimentKey = exposure.experiment.key
    const variationKey = exposure.variationKey
    const exp_variant_string = `HACKLE-EXPERIMENT_${experimentKey}_V${exposure.experiment.version}-${variationKey}`

    try {
      // @ts-ignore
      window.gtag("event", "experience_impression", {
        exp_variant_string: exp_variant_string
      })
    } catch (e) {
      Logger.log.error(`Unexpected exception while sending an event to Google Analytics with gtag: ${e}`)
    }
  }
}
