import EventProcessor from "../../../core/internal/event/EventProcessor"
import UserEvent from "../../../core/internal/event/UserEvent"
import { decorateEventWithSession, SessionManager } from "../../../core/internal/session/SessionManager"
import { UserManager } from "../../../core/internal/user/UserManager"
import { SessionEventTracker } from "../../session/track/index.browser"
import { Visibility, VisibilityChangeListener } from "../../../core/internal/visibility/VisibilityManager"
import { UserEventDedupDeterminer } from "../dedup/UserEventDedupDeterminer"

export default class BrowserEventProcessor implements EventProcessor, VisibilityChangeListener {
  constructor(
    private readonly delegate: EventProcessor,
    private readonly eventDedupDeterminer: UserEventDedupDeterminer,
    private readonly sessionManager: SessionManager,
    private readonly userManager: UserManager
  ) {}

  process(event: UserEvent): void {
    if (!SessionEventTracker.isSessionEvent(event)) {
      this.sessionManager.startNewSessionIfNeeded(this.userManager.currentUser, event.timestamp)
    }

    if (this.eventDedupDeterminer.isDedupTarget(event)) {
      return
    }

    const decoratedEvent = decorateEventWithSession(event, this.sessionManager)
    this.delegate.process(decoratedEvent)
  }

  flush(sync: boolean): void {
    this.delegate.flush(sync)
  }

  close(): void {
    this.delegate.close()
  }

  onVisibilityChanged(visibility: Visibility, _: number): void {
    switch (visibility) {
      case "visible":
        return
      case "hidden":
        this.flush(true)
        return
    }
  }
}
