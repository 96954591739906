import { WorkspaceConfig, WorkspaceConfigStorage } from "../../core/internal/workspace/WorkspaceConfig"
import { IStorage } from "../../core/internal/storage/Storage"
import { WORKSPACE_CONFIG_STORAGE_KEY } from "../../config"
import ObjectUtil from "../../core/internal/util/ObjectUtil"
import { Sdk } from "../../core/internal/model/model"

interface VersionedWorkspaceConfig extends WorkspaceConfig {
  readonly version: string
}

export class BrowserWorkspaceConfigStorage implements WorkspaceConfigStorage {
  constructor(private readonly sdk: Sdk, private readonly storage: IStorage) {}

  get(): WorkspaceConfig | null {
    const json = this.storage.getItem(WORKSPACE_CONFIG_STORAGE_KEY)
    if (ObjectUtil.isNullOrUndefined(json)) return null
    try {
      const config: VersionedWorkspaceConfig = JSON.parse(json)
      if (this.sdk.version !== config.version) {
        this.clear()
        return null
      }
      return config
    } catch (e) {
      this.clear()
      return null
    }
  }

  set(config: WorkspaceConfig): void {
    const versionedConfig: VersionedWorkspaceConfig = {
      ...config,
      version: this.sdk.version
    }
    this.storage.setItem(WORKSPACE_CONFIG_STORAGE_KEY, JSON.stringify(versionedConfig))
  }

  clear(): void {
    this.storage.removeItem(WORKSPACE_CONFIG_STORAGE_KEY)
  }
}
