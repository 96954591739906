import { Transport, TransportRequest, TransportResponse } from "./Transport"
import { SDK_KEY_HEADER, SDK_NAME_HEADER, SDK_TIME_HEADER, SDK_VERSION_HEADER } from "../../../config"

export class BeaconTransport implements Transport {
  static create(): BeaconTransport {
    return new BeaconTransport()
  }

  send(request: TransportRequest): Promise<TransportResponse> {
    return new Promise((resolve, reject) => {
      let beaconSupport = false

      if (typeof window !== "undefined") {
        beaconSupport = window && window.navigator && !!window.navigator.sendBeacon && true
      }
      if (!beaconSupport) {
        throw new Error("BeaconTransport not supported.")
      }

      let body = this.createBody(request)
      const success = window.navigator.sendBeacon(request.url, body)
      if (!success) {
        throw Error("Failed to sendBeacon")
      }

      resolve(TransportResponse.ok())
    })
  }

  private createBody(request: TransportRequest): URLSearchParams {
    const params = new URLSearchParams()
    const sdkKey = request.headers.get(SDK_KEY_HEADER)
    if (sdkKey) {
      params.append("sdkKey", sdkKey)
    }
    params.append("sdkName", request.headers.getOrDefault(SDK_NAME_HEADER, "unknown"))
    params.append("sdkVersion", request.headers.getOrDefault(SDK_VERSION_HEADER, "unknown"))
    const sdkTime = request.headers.get(SDK_TIME_HEADER)
    if (sdkTime) {
      params.append("sdkTime", sdkTime)
    }
    if (request.body) {
      params.append("data", request.body)
    }
    return params
  }
}
