import { Transport, TransportHeaders, TransportRequest, TransportResponse } from "../transport/Transport"
import { WorkspaceConfigDto } from "./dto"
import { TimerSample } from "../metrics/Timer"
import { ApiCallMetrics } from "../metrics/monitoring/MonitoringMetricRegistry"
import { WorkspaceConfig } from "./WorkspaceConfig"

export class HttpWorkspaceFetcher {
  constructor(private readonly url: string, private readonly transport: Transport) {}

  async fetchIfModified(lastModified: string | null): Promise<WorkspaceConfig | null> {
    const request = this.createRequest(lastModified)
    const response = await this.execute(request)
    return this.handleResponse(response)
  }

  private createRequest(lastModified: string | null): TransportRequest {
    const builder = TransportRequest.builder().url(this.url).method("GET")
    if (lastModified !== null) {
      builder.addHeader(TransportHeaders.IF_MODIFIED_SINCE, lastModified)
    }
    return builder.build()
  }

  private async execute(request: TransportRequest): Promise<TransportResponse> {
    const sample = TimerSample.start()
    try {
      const response = await this.transport.send(request)
      ApiCallMetrics.record("get.workspace", sample, response)
      return response
    } catch (e) {
      ApiCallMetrics.record("get.workspace", sample, null)
      throw e
    }
  }

  private handleResponse(response: TransportResponse): WorkspaceConfig | null {
    if (response.isNotModified()) {
      return null
    }
    if (!response.isSuccessful()) {
      throw new Error(`Http status code: ${response.statusCode}`)
    }
    if (response.body === null) {
      throw new Error("Response body is empty")
    }
    const dto = JSON.parse(response.body) as WorkspaceConfigDto
    const lastModified = response.headers.get(TransportHeaders.LAST_MODIFIED)
    return { config: dto, lastModified: lastModified }
  }
}
