import { InAppMessageRequest } from "../evalautor/iam/InAppMessageRequest"
import Evaluator, { EvaluatorContext } from "../evalautor/Evaluator"
import { InAppMessageContextMessage, InAppMessageUserOverride } from "../../model/model"
import ObjectUtil from "../../util/ObjectUtil"
import ExperimentRequest from "../evalautor/experiment/ExperimentRequest"
import ExperimentEvaluation from "../evalautor/experiment/ExperimentEvaluation"
import TargetMatcher from "../match/TargetMatcher"
import InAppMessageHiddenStorage from "./InAppMessageHiddenStorage"

export class InAppMessageResolver {
  constructor(private readonly evaluator: Evaluator) {}

  resolve(request: InAppMessageRequest, context: EvaluatorContext): InAppMessageContextMessage {
    return this.resolveExperiment(request, context) ?? this.resolveDefault(request)
  }

  private resolveExperiment(
    request: InAppMessageRequest,
    context: EvaluatorContext
  ): InAppMessageContextMessage | null {
    const experimentContext = request.inAppMessage.messageContext.experimentContext
    if (!experimentContext) return null

    const experiment = ObjectUtil.requiredNotNullOrUndefined(
      request.workspace.getExperimentOrNull(experimentContext.key),
      () => `Experiment[${experimentContext.key}]`
    )

    const experimentRequest = ExperimentRequest.by(request, experiment)
    const evaluation = this.evaluator.evaluate(experimentRequest, context) as ExperimentEvaluation
    this.addExperimentContext(evaluation, context)

    const lang = request.inAppMessage.messageContext.defaultLang
    return this.resolveMessage(request, (it) => it.lang === lang && evaluation.variationKey === it.variationKey)
  }

  private addExperimentContext(evaluation: ExperimentEvaluation, context: EvaluatorContext) {
    context.addEvaluation(evaluation)
    context.setProperty("experiment_id", evaluation.experiment.id)
    context.setProperty("experiment_key", evaluation.experiment.key)
    context.setProperty("variation_id", evaluation.variationId)
    context.setProperty("variation_key", evaluation.variationKey)
    context.setProperty("experiment_decision_reason", evaluation.reason)
  }

  private resolveDefault(request: InAppMessageRequest): InAppMessageContextMessage {
    const lang = request.inAppMessage.messageContext.defaultLang
    return this.resolveMessage(request, (it) => it.lang === lang)
  }

  private resolveMessage(
    request: InAppMessageRequest,
    condition: (message: InAppMessageContextMessage) => boolean
  ): InAppMessageContextMessage {
    const message = request.inAppMessage.messageContext.messages.find(condition)
    return ObjectUtil.requiredNotNullOrUndefined(
      message,
      () => `InAppMessage must be decided [${request.inAppMessage.id}]`
    )
  }
}

export interface InAppMessageMatcher {
  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean
}

export class UserOverrideInAppMessageMatcher implements InAppMessageMatcher {
  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean {
    return request.inAppMessage.targetContext.overrides.some((it) => this.isUserOverridden(request, it))
  }

  private isUserOverridden(request: InAppMessageRequest, userOverride: InAppMessageUserOverride): boolean {
    const identifier = request.user.identifiers[userOverride.identifierType]
    if (ObjectUtil.isNullOrUndefined(identifier)) {
      return false
    }
    return userOverride.identifiers.includes(identifier)
  }
}

export class TargetInAppMessageMatcher implements InAppMessageMatcher {
  constructor(private readonly targetMatcher: TargetMatcher) {}

  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean {
    const targets = request.inAppMessage.targetContext.targets
    if (targets.length === 0) {
      return true
    }
    return targets.some((it) => this.targetMatcher.matches(request, context, it))
  }
}

export class HiddenInAppMessageMatcher implements InAppMessageMatcher {
  constructor(private readonly storage: InAppMessageHiddenStorage) {}

  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean {
    return this.storage.exist(request.inAppMessage, request.timestamp)
  }
}
