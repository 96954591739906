import { Cohort, Identifier, resolveIdentifiers, User } from "../model/model"
import { Comparable } from "../util/Comparable"
import IdentifierUtil from "../util/IdentifierUtil"
import { UserCohortsResponseDto } from "./dto"

export interface UserCohort {
  readonly identifier: Identifier
  readonly cohorts: Cohort[]
}

export class UserCohorts {
  constructor(private readonly cohorts: Comparable<Identifier, UserCohort>) {}

  static builder(): UserCohortsBuilder {
    return new UserCohortsBuilder()
  }

  static empty(): UserCohorts {
    return UserCohorts.builder().build()
  }

  static from(cohorts: Comparable<Identifier, UserCohort>): UserCohorts {
    return new UserCohorts(cohorts)
  }

  static fromDto(dto: UserCohortsResponseDto): UserCohorts {
    const builder = UserCohorts.builder()
    for (const cohort of dto.cohorts) {
      builder.put({
        identifier: cohort.identifier,
        cohorts: cohort.cohorts.map((id) => new Cohort(id))
      })
    }
    return builder.build()
  }

  get rawCohorts(): Cohort[] {
    return this.cohorts.values().flatMap((it) => it.cohorts)
  }

  get(identifier: Identifier): UserCohort | undefined {
    return this.cohorts.get(identifier)
  }

  filterBy(user: User): UserCohorts {
    const identifiers = resolveIdentifiers(user)
    const filtered = this.asComparable().filter((identifier, _) => identifiers[identifier.type] === identifier.value)
    return UserCohorts.from(filtered)
  }

  asArray(): UserCohort[] {
    return this.cohorts.values()
  }

  asComparable(): Comparable<Identifier, UserCohort> {
    return this.cohorts
  }

  toBuilder(): UserCohortsBuilder {
    return new UserCohortsBuilder(this)
  }
}

export class UserCohortsBuilder {
  private readonly cohorts: Comparable<Identifier, UserCohort>

  constructor(cohorts?: UserCohorts) {
    this.cohorts = new Comparable<Identifier, UserCohort>(IdentifierUtil.isEqual)
    if (cohorts) {
      this.putAll(cohorts)
    }
  }

  put(cohort: UserCohort): UserCohortsBuilder {
    this.cohorts.add(cohort.identifier, cohort)
    return this
  }

  putAll(cohorts: UserCohorts): UserCohortsBuilder {
    cohorts.asArray().forEach((it) => this.put(it))
    return this
  }

  build(): UserCohorts {
    return new UserCohorts(this.cohorts)
  }
}
