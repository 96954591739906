import {
  DecisionReason,
  HackleRemoteConfig,
  HackleValue,
  RemoteConfigDecision,
  User
} from "../../core/internal/model/model"
import HackleCore from "../../core/HackleCore"
import { UserManager } from "../../core/internal/user/UserManager"
import Logger from "../../core/internal/logger"
import { TimerSample } from "../../core/internal/metrics/Timer"
import { DecisionMetrics } from "../../core/internal/metrics/monitoring/MonitoringMetricRegistry"
import HackleRemoteConfigDecider from "./HackleRemoteConfigDecider"

const log = Logger.log

export default class HackleRemoteConfigImpl implements HackleRemoteConfig {
  constructor(
    private readonly core: HackleCore,
    private readonly userManager: UserManager,
    private readonly user?: User
  ) {}

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    const sample = TimerSample.start()
    let decision: RemoteConfigDecision
    try {
      decision = this.decide(key, defaultValue)
    } catch (e) {
      log.error(`Unexpected exception while deciding remote config parameter[${key}]. Returning default value. : ${e}`)
      decision = RemoteConfigDecision.of(defaultValue, DecisionReason.EXCEPTION)
    }
    DecisionMetrics.remoteConfig(sample, key, decision)
    return decision.value
  }

  private decide(key: string, defaultValue: HackleValue): RemoteConfigDecision {
    const hackleUser = this.userManager.resolve(this.user)
    return HackleRemoteConfigDecider.decide(this.core, key, defaultValue, hackleUser)
  }
}
