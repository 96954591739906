import PropertyUtil from "../../core/internal/util/PropertyUtil"
import ObjectUtil from "../../core/internal/util/ObjectUtil"

export class PropertiesBuilder {
  private _properties: Map<string, any> = new Map()
  private MAX_PROPERTIES_COUNT = 128
  private MAX_PROPERTY_KEY_LENGTH = 128

  contains(key: string) {
    return this._properties.has(key)
  }

  remove(target: string | Map<string, any>) {
    if (target instanceof Map) {
      target.forEach((_value, key) => {
        this.remove(key)
      })
    }

    if (typeof target === "string") {
      this._properties.delete(target)
    }

    return this
  }

  compute(key: string, remapping: (mapper: any) => any) {
    const oldValue = this._properties.get(key)
    const newValue = remapping(oldValue)

    if (newValue !== null) {
      this.add(key, newValue)
    } else {
      if (oldValue !== null || this.contains(key)) {
        this.remove(key)
      }
    }
  }

  add(key: string, value: any, setOnce: boolean = false) {
    if (setOnce && this.contains(key)) return this
    if (this._properties.size >= this.MAX_PROPERTIES_COUNT) return this
    if (typeof key === "string" && key.length > this.MAX_PROPERTY_KEY_LENGTH) return this

    const sanitizedValue = PropertyUtil.sanitizeValue(key, value)
    if (ObjectUtil.isNullOrUndefined(sanitizedValue)) return this

    this._properties.set(key, sanitizedValue)
    return this
  }

  addProperties(properties: Map<string, any>, setOnce: boolean = false) {
    properties.forEach((value, key) => {
      this.add(key, value, setOnce)
    })

    return this
  }

  build() {
    return this._properties
  }
}
