import ObjectUtil from "../../core/internal/util/ObjectUtil"
import PropertyUtil from "../../core/internal/util/PropertyUtil"
import { HackleEvent } from "../../index.browser"
import { PropertiesBuilder } from "./PropertiesBuilder"
import { PropertyOperation } from "./PropertyOperation"
import { PropertyOperator } from "./PropertyOperator"
import { PropertyOperators } from "./PropertyOperators"

export class PropertyOperationsBuilder {
  private operations: Map<PropertyOperation, PropertiesBuilder> = new Map()

  set(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.SET, key, value)
  }
  setOnce(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.SET_ONCE, key, value)
  }
  unset(key: string): PropertyOperationsBuilder {
    return this.add(PropertyOperation.UNSET, key, "-")
  }
  increment(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.INCREMENT, key, value)
  }
  append(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.APPEND, key, value)
  }
  appendOnce(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.APPEND_ONCE, key, value)
  }
  prepend(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.PREPEND, key, value)
  }
  prependOnce(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.PREPEND_ONCE, key, value)
  }
  remove(key: string, value: any): PropertyOperationsBuilder {
    return this.add(PropertyOperation.REMOVE, key, value)
  }
  clearAll(): PropertyOperationsBuilder {
    return this.add(PropertyOperation.CLEAR_ALL, "clearAll", "-")
  }
  build(): PropertyOperations {
    const builderPropertyOperation = Array.from(this.operations.entries()).map<[PropertyOperation, Map<string, any>]>(
      ([operation, builder]) => [operation, builder.build()]
    )
    return new PropertyOperations(new Map(builderPropertyOperation))
  }

  private containsKey(key: string) {
    return Array.from(this.operations.entries()).some(([_operation, builder]) => builder.contains(key))
  }

  private add(operation: PropertyOperation, key: string, value: any): PropertyOperationsBuilder {
    if (this.containsKey(key)) return this

    if (!this.operations.has(operation)) {
      this.operations.set(operation, new PropertiesBuilder())
    }

    const _builder = this.operations.get(operation)
    _builder?.add(key, value)

    return this
  }
}

type PropertyOperationsRecord = Map<PropertyOperation, Map<string, any>>

export class PropertyOperations {
  private operations: PropertyOperationsRecord
  constructor(operations?: PropertyOperationsRecord) {
    this.operations = operations ?? new Map()
  }

  operate(base: Map<string, any>): Map<string, any> {
    let accumulator = base
    this.operations.forEach((value, key) => {
      accumulator = PropertyOperators.get(key).operate(accumulator, value)
    })

    return accumulator
  }

  toEvent() {
    const event: HackleEvent<PropertyOperation> = {
      key: "$properties"
    }

    this.operations.forEach((properties, operation) => {
      event.properties = {
        ...event.properties,
        [operation]: {
          ...event.properties?.[operation],
          ...PropertyUtil.sanitize(ObjectUtil.fromMap(properties))
        }
      }
    })

    return event
  }

  toRecord(): Record<string, Record<string, any>> {
    const object: Record<string, Record<string, any>> = {}

    this.operations.forEach((properties, operation) => {
      const childValue: Record<string, Record<string, any>> = {}

      properties.forEach((value, key) => {
        childValue[key] = value
      })

      object[operation] = childValue
    })
    return object
  }
}
