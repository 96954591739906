import UserEvent from "../../../core/internal/event/UserEvent"
import { UserEventDedupDeterminer } from "./UserEventDedupDeterminer"
import { HackleUser } from "../../../core/internal/model/model"
import { Clock } from "../../../core/internal/util/TimeUtil"
import { NO_DEDUP } from "../../../config"
import IdentifierUtil from "../../../core/internal/util/IdentifierUtil"

export abstract class CachedUserEventDeterminer<Event extends UserEvent> implements UserEventDedupDeterminer {
  private cache: Record<string, number> = {}
  private currentUser: HackleUser | null = null

  protected constructor(private readonly dedupIntervalMillis: number, private readonly clock: Clock) {}

  isDedupTarget(event: Event): boolean {
    if (this.dedupIntervalMillis === NO_DEDUP) {
      return false
    }

    if (!IdentifierUtil.isEquals(event.user.identifiers, this.currentUser?.identifiers)) {
      this.currentUser = event.user
      this.cache = {}
    }

    const key = this.cacheKey(event)
    const now = this.clock.currentMillis()

    const firstMillis = this.cache[key]
    if (firstMillis !== undefined && now - firstMillis <= this.dedupIntervalMillis) {
      return true
    }

    this.cache[key] = now
    return false
  }

  abstract supports(event: UserEvent): event is Event

  abstract cacheKey(event: Event): string
}
