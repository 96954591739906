import {
  InAppMessageActionEvent,
  InAppMessageCloseEvent,
  InAppMessageEvent,
  InAppMessageImpressionEvent
} from "./InAppMessageEvent"
import { InAppMessageView } from "../view/InAppMessageView"
import { InAppMessageImpression, InAppMessageImpressionStorage } from "../../storage/InAppMessageImpressionStorage"
import { HackleUser, InAppMessage } from "../../../../core/internal/model/model"
import { InAppMessageActionHandlerFactory } from "./InAppMessageActionHandler"

export interface InAppMessageEventProcessor<Event extends InAppMessageEvent> {
  supports(event: InAppMessageEvent): boolean

  process(view: InAppMessageView, event: Event, timestamp: number): void
}

export class InAppMessageEventProcessorFactory {
  constructor(private readonly processors: InAppMessageEventProcessor<InAppMessageEvent>[]) {}

  get(event: InAppMessageEvent): InAppMessageEventProcessor<InAppMessageEvent> | undefined {
    return this.processors.find((it) => it.supports(event))
  }
}

export class InAppMessageImpressionEventProcessor implements InAppMessageEventProcessor<InAppMessageImpressionEvent> {
  private static IMPRESSION_MAX_SIZE = 100

  constructor(private readonly impressionStorage: InAppMessageImpressionStorage) {}

  process(view: InAppMessageView, event: InAppMessageImpressionEvent, timestamp: number): void {
    this.saveImpression(view.context.inAppMessage, view.context.user, timestamp)
  }

  private saveImpression(inAppMessage: InAppMessage, user: HackleUser, timestamp: number): void {
    const impressions = this.impressionStorage.get(inAppMessage)
    const impression: InAppMessageImpression = { identifiers: user.identifiers, timestamp: timestamp }
    impressions.push(impression)

    if (impressions.length > InAppMessageImpressionEventProcessor.IMPRESSION_MAX_SIZE) {
      impressions.splice(0, impressions.length - InAppMessageImpressionEventProcessor.IMPRESSION_MAX_SIZE)
    }
    this.impressionStorage.set(inAppMessage, impressions)
  }

  supports(event: InAppMessageEvent): boolean {
    return event.type === "IMPRESSION"
  }
}

export class InAppMessageCloseEventProcessor implements InAppMessageEventProcessor<InAppMessageCloseEvent> {
  process(view: InAppMessageView, event: InAppMessageCloseEvent, timestamp: number): void {
    // Do nothing. This method is called after the view is closed.
  }

  supports(event: InAppMessageEvent): boolean {
    return event.type === "CLOSE"
  }
}

export class InAppMessageActionEventProcessor implements InAppMessageEventProcessor<InAppMessageActionEvent> {
  constructor(private readonly actionHandlerFactory: InAppMessageActionHandlerFactory) {}

  process(view: InAppMessageView, event: InAppMessageActionEvent, timestamp: number): void {
    const handler = this.actionHandlerFactory.get(event.action)
    if (!handler) return
    handler.handle(view, event.action)
  }

  supports(event: InAppMessageEvent): boolean {
    return event.type === "ACTION"
  }
}
