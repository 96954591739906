import { DecisionReason } from "../../../model/model"
import ContextualEvaluator from "../ContextualEvaluator"
import { EvaluatorContext, EvaluatorRequest } from "../Evaluator"
import { InAppMessageEvaluation } from "./InAppMessageEvaluation"
import { InAppMessageRequest } from "./InAppMessageRequest"
import EvaluationFlowFactory from "../../flow/EvaluationFlowFactory"

export class InAppMessageEvaluator extends ContextualEvaluator<InAppMessageRequest, InAppMessageEvaluation> {
  constructor(private readonly evaluationFlowFactory: EvaluationFlowFactory) {
    super()
  }

  public supports(request: EvaluatorRequest): request is InAppMessageRequest {
    return (request as InAppMessageRequest).inAppMessage !== undefined
  }

  public evaluateInternal(request: InAppMessageRequest, context: EvaluatorContext): InAppMessageEvaluation {
    const evaluationFlow = this.evaluationFlowFactory.getInAppMessageFlow()
    return (
      evaluationFlow.evaluate(request, context) ??
      InAppMessageEvaluation.of(request, context, DecisionReason.NOT_IN_IN_APP_MESSAGE_TARGET)
    )
  }
}
