import UrlUtil from "../../core/internal/util/UrlUtil"
import { SplitUrlConfigDto } from "../../core/internal/workspace/dto"
import { SplitUrlContext } from "./SplitUrlContext"
import { SplitUrlConfig, SplitUrlRedirectType } from "../../core/internal/model/model"
import { CampaignParser } from "../attribution/CampaignParser"
import { setCampaignToUrl } from "../attribution/Campaign"
import { Strings } from "../../core/internal/util/Strings"

export class SplitUrlResolver {
  constructor(private readonly campaignParser: CampaignParser) {}

  static readonly SPLIT_URL_CONFIG_KEY = "redirectUrl"

  resolveOrNull(context: SplitUrlContext): URL | null {
    try {
      const splitUrlConfigDto: SplitUrlConfigDto = JSON.parse(
        context.decision.get(SplitUrlResolver.SPLIT_URL_CONFIG_KEY, "")
      )
      const splitUrlConfig = SplitUrlConfig.fromDto(splitUrlConfigDto)

      if (!splitUrlConfig.needRedirect) {
        return null
      }

      return this.resolveByRedirectType(splitUrlConfig.redirectType, splitUrlConfig.redirectUrl, context)
    } catch (e) {
      return null
    }
  }

  private resolveByRedirectType(
    type: SplitUrlRedirectType | null,
    redirectUrl: string | null,
    context: SplitUrlContext
  ): URL | null {
    if (!redirectUrl) {
      return null
    }

    switch (type) {
      case SplitUrlRedirectType.MANUAL:
        return this.resolveManualRedirectUrl(redirectUrl, context)
      default:
        return null
    }
  }

  private resolveManualRedirectUrl(redirectUrl: string, context: SplitUrlContext): URL | null {
    const url = UrlUtil.tryParse(redirectUrl)
    if (!url) return null

    // Decorate campaign for MANUAL redirect
    return this.decorateCampaign(url, context)
  }

  private decorateCampaign(url: URL, context: SplitUrlContext): URL {
    const search = context.event.user.hackleProperties["queryParameter"]
    if (Strings.isNotEmptyString(search)) {
      const campaign = this.campaignParser.parse(search)
      return setCampaignToUrl(campaign, url)
    } else {
      return url
    }
  }
}
