import { ConditionMatcherFactory } from "./ConditionMatcher"
import { Target, TargetCondition } from "../../model/model"
import { EvaluatorContext, EvaluatorRequest } from "../evalautor/Evaluator"

export default class TargetMatcher {
  private conditionMatcherFactory: ConditionMatcherFactory

  constructor(conditionMatcherFactory: ConditionMatcherFactory) {
    this.conditionMatcherFactory = conditionMatcherFactory
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, target: Target): boolean {
    return target.conditions.every((it) => this._matches(request, context, it))
  }

  anyMatches(request: EvaluatorRequest, context: EvaluatorContext, targets: Target[]): boolean {
    if (!targets.length) return true
    return targets.some((target) => this.matches(request, context, target))
  }

  private _matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    const conditionMatcher = this.conditionMatcherFactory.getMatcher(condition.key.type)
    return conditionMatcher.matches(request, context, condition)
  }
}
