import { InAppMessagePresentationContext, InAppMessagePresenter } from "../presentation/InAppMessagePresenter"
import { InAppMessageView } from "./view/InAppMessageView"
import { InAppMessageViewFactory } from "./view/InAppMessageViewFactory"
import { InAppMessageEventHandler } from "./event/InAppMessageEventHandler"
import { InAppMessageInteractionHandlerFactory } from "./event/InAppMessageInteractionHandler"

export class InAppMessageUi implements InAppMessagePresenter {
  public currentMessageView: InAppMessageView | null = null
  private opening: boolean = false

  constructor(
    private readonly messageViewFactory: InAppMessageViewFactory,
    public readonly eventHandler: InAppMessageEventHandler,
    public readonly interactionHandlerFactory: InAppMessageInteractionHandlerFactory
  ) {}

  async present(context: InAppMessagePresentationContext) {
    if (this.currentMessageView) return
    if (this.opening) return

    this.opening = true

    let messageView: InAppMessageView | null = null
    try {
      messageView = this.messageViewFactory.create(context, this)
      this.currentMessageView = messageView
      await messageView.open()
    } catch (e) {
      messageView?.close()
    } finally {
      this.opening = false
    }
  }
}
