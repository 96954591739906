import { InAppMessageAction } from "../../../../core/internal/model/model"
import { InAppMessageView } from "../view/InAppMessageView"
import InAppMessageHiddenStorage from "../../../../core/internal/evaluation/target/InAppMessageHiddenStorage"
import { Clock } from "../../../../core/internal/util/TimeUtil"
import { UrlHandler } from "../internal/UrlHandler"
import Logger from "../../../../core/internal/logger"

export interface InAppMessageActionHandler {
  supports(action: InAppMessageAction): boolean

  handle(view: InAppMessageView, action: InAppMessageAction): void
}

export class InAppMessageActionHandlerFactory {
  constructor(private readonly handlers: InAppMessageActionHandler[]) {}

  get(action: InAppMessageAction): InAppMessageActionHandler | undefined {
    return this.handlers.find((it) => it.supports(action))
  }
}

export class InAppMessageCloseActionHandler implements InAppMessageActionHandler {
  handle(view: InAppMessageView, action: InAppMessageAction): void {
    view.close()
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "CLOSE"
  }
}

export class InAppMessageLinkActionHandler implements InAppMessageActionHandler {
  constructor(private urlHandler: UrlHandler) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    try {
      if (!action.value) return
      this.urlHandler.open(new URL(action.value))
    } catch (e) {
      Logger.log.error(`Invalid url: ${action.value}`)
    }
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "WEB_LINK"
  }
}

export class InAppMessageLinkAndCloseActionHandler implements InAppMessageActionHandler {
  constructor(private urlHandler: UrlHandler) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    view.close()

    try {
      if (!action.value) return
      this.urlHandler.open(new URL(action.value))
    } catch (e) {
      Logger.log.error(`Invalid url: ${action.value}`)
    }
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "LINK_AND_CLOSE"
  }
}

export class InAppMessageHiddenActionHandler implements InAppMessageActionHandler {
  // 24 hours in ms
  private static HIDE_EXPIRATION: number = 24 * 60 * 60 * 1000

  constructor(private readonly storage: InAppMessageHiddenStorage, private readonly clock: Clock) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    const expireAt = this.clock.currentMillis() + InAppMessageHiddenActionHandler.HIDE_EXPIRATION
    this.storage.put(view.context.inAppMessage, expireAt)
    view.close()
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "HIDDEN"
  }
}

export class InAppMessageLinkNewWindowActionHandler implements InAppMessageActionHandler {
  constructor(private urlHandler: UrlHandler) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    try {
      if (!action.value) return
      this.urlHandler.open(new URL(action.value))
    } catch (e) {
      Logger.log.error(`Invalid url: ${action.value}`)
    }
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "LINK_NEW_WINDOW"
  }
}

export class InAppMessageLinkNewWindowAndCloseActionHandler implements InAppMessageActionHandler {
  constructor(private urlHandler: UrlHandler) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    view.close()

    try {
      if (!action.value) return
      this.urlHandler.open(new URL(action.value))
    } catch (e) {
      Logger.log.error(`Invalid url: ${action.value}`)
    }
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "LINK_NEW_WINDOW_AND_CLOSE"
  }
}

export class InAppMessageLinkNewTabActionHandler implements InAppMessageActionHandler {
  constructor(private urlHandler: UrlHandler) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    try {
      if (!action.value) return
      this.urlHandler.open(new URL(action.value))
    } catch (e) {
      Logger.log.error(`Invalid url: ${action.value}`)
    }
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "LINK_NEW_TAB"
  }
}

export class InAppMessageLinkNewTabAndCloseActionHandler implements InAppMessageActionHandler {
  constructor(private urlHandler: UrlHandler) {}

  handle(view: InAppMessageView, action: InAppMessageAction): void {
    view.close()

    try {
      if (!action.value) return
      this.urlHandler.open(new URL(action.value))
    } catch (e) {
      Logger.log.error(`Invalid url: ${action.value}`)
    }
  }

  supports(action: InAppMessageAction): boolean {
    return action.type === "LINK_NEW_TAB_AND_CLOSE"
  }
}
