import { Clock, SystemClock } from "../util/TimeUtil"

export class Throttler {
  constructor(private readonly limiter: ThrottleLimiter) {}

  static create(intervalMillis: number, limit: number, clock: Clock = SystemClock.instance): Throttler {
    const limiter = new ThrottleLimiter(clock, intervalMillis, limit)
    return new Throttler(limiter)
  }

  execute<T>(accept: () => T, reject: () => T): T {
    if (this.limiter.tryAcquire()) {
      return accept()
    } else {
      return reject()
    }
  }
}

export class ThrottleLimiter {
  private currentScope: ThrottleScope | null = null

  constructor(private readonly clock: Clock, private readonly intervalMillis: number, private readonly limit: number) {}

  tryAcquire(): boolean {
    const now = this.clock.currentMillis()
    const scope = this.refreshScopeIfNeeded(now)
    return scope.tryAcquire()
  }

  private refreshScopeIfNeeded(now: number): ThrottleScope {
    if (this.currentScope == null || this.currentScope.isExpired(now)) {
      this.currentScope = new ThrottleScope(now + this.intervalMillis, this.limit)
    }
    return this.currentScope
  }
}

class ThrottleScope {
  constructor(private readonly expirationTime: number, private token: number) {}

  isExpired(now: number): boolean {
    return this.expirationTime < now
  }

  tryAcquire(): boolean {
    if (this.token > 0) {
      this.token -= 1
      return true
    } else {
      return false
    }
  }
}
