import { Synchronizer } from "./Synchronizer"
import Logger from "../logger"

export type SynchronizerType = "workspace" | "cohort"

interface Synchronization {
  type: SynchronizerType
  synchronizer: Synchronizer
}

export class CompositeSynchronizer implements Synchronizer {
  private readonly synchronizations: Synchronization[] = []

  add(type: SynchronizerType, synchronizer: Synchronizer) {
    this.synchronizations.push({ type, synchronizer })
  }

  async sync(): Promise<void> {
    await Promise.all(this.synchronizations.map((it) => this.safe(it.synchronizer.sync())))
  }

  async syncOnly(type: SynchronizerType): Promise<void> {
    const synchronization = this.synchronizations.find((it) => it.type === type)
    if (!synchronization) {
      throw new Error(`Unsupported SynchronizerType [${type}]`)
    }
    await synchronization.synchronizer.sync()
  }

  async close(): Promise<void> {
    await Promise.all(this.synchronizations.map((it) => this.safe(it.synchronizer.close())))
  }

  private async safe(promise: Promise<void>): Promise<void> {
    try {
      await promise
    } catch (e) {
      Logger.log.error(`Failed to sync: ${e}`)
    }
  }
}
