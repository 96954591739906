import HackleCore from "../../../core/HackleCore"
import UserEvent from "../../../core/internal/event/UserEvent"
import { HackleEvent, HackleUser, IdentifiersBuilder, IdentifierType, User } from "../../../core/internal/model/model"
import { SessionListener } from "../../../core/internal/session/SessionListener"
import { UserManager } from "../../../core/internal/user/UserManager"

export class SessionEventTracker implements SessionListener {
  private static SESSION_START_EVENT_NAME = "$session_start"
  private static SESSION_END_EVENT_NAME = "$session_end"

  public static isSessionEvent(event: UserEvent) {
    if (!UserEvent.isTrackEvent(event)) {
      return false
    }

    return (
      event.event.key === SessionEventTracker.SESSION_START_EVENT_NAME ||
      event.event.key === SessionEventTracker.SESSION_END_EVENT_NAME
    )
  }

  constructor(private userManager: UserManager, private core: HackleCore) {}

  onSessionStarted(sessionId: string, user: User, timestamp: number): void {
    this.track(SessionEventTracker.SESSION_START_EVENT_NAME, sessionId, user, timestamp)
  }

  onSessionEnded(sessionId: string, user: User, timestamp: number): void {
    this.track(SessionEventTracker.SESSION_END_EVENT_NAME, sessionId, user, timestamp)
  }

  private track(eventKey: string, sessionId: string, user: User, timestamp: number) {
    const hackleEvent: HackleEvent = { key: eventKey }
    const hackleUser = this.userManager.toHackleUser(user)
    const decoratedUser = this.decorateSession(hackleUser, sessionId)
    this.core.track(hackleEvent, decoratedUser, timestamp)
  }

  private decorateSession(user: HackleUser, sessionId: string): HackleUser {
    const identifiers = new IdentifiersBuilder()
      .add(IdentifierType.SESSION, sessionId)
      .addIdentifiers(user.identifiers)
      .build()
    return {
      ...user,
      identifiers
    }
  }
}
