import { InAppMessageView } from "../view/InAppMessageView"
import { InAppMessageEvent } from "./InAppMessageEvent"
import { Clock } from "../../../../core/internal/util/TimeUtil"
import { InAppMessageEventTracker } from "./InAppMessageEventTracker"
import { InAppMessageEventProcessorFactory } from "./InAppMessageEventProcessor"

export class InAppMessageEventHandler {
  constructor(
    private readonly clock: Clock,
    private readonly eventTracker: InAppMessageEventTracker,
    private readonly eventProcessorFactory: InAppMessageEventProcessorFactory
  ) {}

  handle(view: InAppMessageView, event: InAppMessageEvent): void {
    const timestamp = this.clock.currentMillis()
    this.eventTracker.track(view.context, event, timestamp)
    const processor = this.eventProcessorFactory.get(event)
    if (!processor) return
    processor.process(view, event, timestamp)
  }
}
