export const TIME_UNIT = ["nanoseconds", "microseconds", "milliseconds", "seconds", "minutes", "hours", "days"] as const
export type TimeUnit = typeof TIME_UNIT[number]

export class TimeUtil {
  private static C0 = 1
  private static C1 = TimeUtil.C0 * 1000
  private static C2 = TimeUtil.C1 * 1000
  private static C3 = TimeUtil.C2 * 1000
  private static C4 = TimeUtil.C3 * 60
  private static C5 = TimeUtil.C4 * 60
  private static C6 = TimeUtil.C5 * 24

  static converter: Record<TimeUnit, (second: number, unit: TimeUnit) => number> = {
    nanoseconds: TimeUtil.nanosToUnit,
    microseconds: TimeUtil.microsToUnit,
    milliseconds: TimeUtil.millisToUnit,
    seconds: TimeUtil.secondsToUnit,
    minutes: TimeUtil.minutesToUnit,
    hours: TimeUtil.hoursToUnit,
    days: TimeUtil.daysToUnit
  }

  static nanosToUnit(nanos: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return nanos
      case "microseconds":
        return nanos / (TimeUtil.C1 / TimeUtil.C0)
      case "milliseconds":
        return nanos / (TimeUtil.C2 / TimeUtil.C0)
      case "seconds":
        return nanos / (TimeUtil.C3 / TimeUtil.C0)
      case "minutes":
        return nanos / (TimeUtil.C4 / TimeUtil.C0)
      case "hours":
        return nanos / (TimeUtil.C5 / TimeUtil.C0)
      case "days":
        return nanos / (TimeUtil.C6 / TimeUtil.C0)
    }
  }

  static microsToUnit(micros: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return micros * (TimeUtil.C1 / TimeUtil.C0)
      case "milliseconds":
        return micros / (TimeUtil.C2 / TimeUtil.C1)
      case "microseconds":
        return micros
      case "seconds":
        return micros / (TimeUtil.C3 / TimeUtil.C1)
      case "minutes":
        return micros / (TimeUtil.C4 / TimeUtil.C1)
      case "hours":
        return micros / (TimeUtil.C5 / TimeUtil.C1)
      case "days":
        return micros / (TimeUtil.C6 / TimeUtil.C1)
    }
  }

  static millisToUnit(millis: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return millis * (TimeUtil.C2 / TimeUtil.C0)
      case "microseconds":
        return millis * (TimeUtil.C2 / TimeUtil.C1)
      case "milliseconds":
        return millis
      case "seconds":
        return millis / (TimeUtil.C3 / TimeUtil.C2)
      case "minutes":
        return millis / (TimeUtil.C4 / TimeUtil.C2)
      case "hours":
        return millis / (TimeUtil.C5 / TimeUtil.C2)
      case "days":
        return millis / (TimeUtil.C6 / TimeUtil.C2)
    }
  }

  static secondsToUnit(seconds: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return seconds * (TimeUtil.C3 / TimeUtil.C0)
      case "microseconds":
        return seconds * (TimeUtil.C3 / TimeUtil.C1)
      case "milliseconds":
        return seconds * (TimeUtil.C3 / TimeUtil.C2)
      case "seconds":
        return seconds
      case "minutes":
        return seconds / (TimeUtil.C4 / TimeUtil.C3)
      case "hours":
        return seconds / (TimeUtil.C5 / TimeUtil.C3)
      case "days":
        return seconds / (TimeUtil.C6 / TimeUtil.C3)
    }
  }

  static minutesToUnit(minutes: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return minutes * (TimeUtil.C4 / TimeUtil.C0)
      case "microseconds":
        return minutes * (TimeUtil.C4 / TimeUtil.C1)
      case "milliseconds":
        return minutes * (TimeUtil.C4 / TimeUtil.C2)
      case "seconds":
        return minutes * (TimeUtil.C4 / TimeUtil.C3)
      case "minutes":
        return minutes
      case "hours":
        return minutes / (TimeUtil.C5 / TimeUtil.C4)
      case "days":
        return minutes / (TimeUtil.C6 / TimeUtil.C4)
    }
  }

  static hoursToUnit(hours: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return hours * (TimeUtil.C5 / TimeUtil.C0)
      case "microseconds":
        return hours * (TimeUtil.C5 / TimeUtil.C1)
      case "milliseconds":
        return hours * (TimeUtil.C5 / TimeUtil.C2)
      case "seconds":
        return hours * (TimeUtil.C5 / TimeUtil.C3)
      case "minutes":
        return hours * (TimeUtil.C5 / TimeUtil.C4)
      case "hours":
        return hours
      case "days":
        return hours / (TimeUtil.C6 / TimeUtil.C5)
    }
  }

  static daysToUnit(days: number, unit: TimeUnit) {
    switch (unit) {
      case "nanoseconds":
        return days * (TimeUtil.C6 / TimeUtil.C0)
      case "microseconds":
        return days * (TimeUtil.C6 / TimeUtil.C1)
      case "milliseconds":
        return days * (TimeUtil.C6 / TimeUtil.C2)
      case "seconds":
        return days * (TimeUtil.C6 / TimeUtil.C3)
      case "minutes":
        return days * (TimeUtil.C6 / TimeUtil.C4)
      case "hours":
        return days * (TimeUtil.C6 / TimeUtil.C5)
      case "days":
        return days
    }
  }
}

export interface Clock {
  tick(): number

  currentMillis(): number
}

export class SystemClock implements Clock {
  static instance = new SystemClock()

  tick(): number {
    return Date.now() * 1e3
  }

  currentMillis(): number {
    return Date.now()
  }
}
