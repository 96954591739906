import { SplitUrlContext } from "./SplitUrlContext"
import { UrlHandler } from "../iam/ui/internal/UrlHandler"
import { SplitUrlRedirectedCookieStorage } from "./SplitUrlRedirectedCookieStorage"
import { SplitUrlResolver } from "./SplitUrlResolver"
import BrowserInitializeDoneEventDispatcher from "../../core/internal/event/BrowserInitializeDoneEventDispatcher"

export class SplitUrlRedirector {
  constructor(
    private readonly urlResolver: SplitUrlResolver,
    private readonly urlHandler: UrlHandler,
    private readonly splitUrlRedirectedCookieStorage: SplitUrlRedirectedCookieStorage,
    private readonly initializeEventDispatcher: BrowserInitializeDoneEventDispatcher
  ) {}

  redirect(context: SplitUrlContext): void {
    const url = this.urlResolver.resolveOrNull(context)
    if (!url) return

    this.initializeEventDispatcher.setNeedDispatch(false)
    this.splitUrlRedirectedCookieStorage.setRedirected(true)
    this.urlHandler.open(url)
  }
}
