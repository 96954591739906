import Workspace from "../../../core/internal/workspace/Workspace"
import { InAppMessage } from "../../../core/internal/model/model"
import UserEvent from "../../../core/internal/event/UserEvent"
import { InAppMessageEventTriggerDeterminer } from "./InAppMessageEventTriggerDeterminer"

export class InAppMessageEventMatcher {
  constructor(
    private readonly ruleDeterminer: InAppMessageEventTriggerDeterminer,
    private readonly frequencyCapDeterminer: InAppMessageEventTriggerDeterminer
  ) {}

  matches(workspace: Workspace, inAppMessage: InAppMessage, event: UserEvent): boolean {
    if (!UserEvent.isTrackEvent(event)) return false
    if (!this.ruleDeterminer.isTriggerTarget(workspace, inAppMessage, event)) {
      return false
    }

    return this.frequencyCapDeterminer.isTriggerTarget(workspace, inAppMessage, event)
  }
}
