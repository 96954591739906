import { SessionListener } from "../../../core/internal/session/SessionListener"
import { SessionManager } from "../../../core/internal/session/SessionManager"
import { UserListener } from "../../../core/internal/user/UserListener"
import { User } from "../../../index.browser"
import { IStorage } from "../../../core/internal/storage/Storage"
import { createSessionId } from "../../../core/internal/session/Session"
import { LAST_EVENT_TIME_STORAGE_KEY, SESSION_ID_STORAGE_KEY } from "../../../config"
import { Visibility, VisibilityChangeListener } from "../../../core/internal/visibility/VisibilityManager"

export class SessionManagerImpl implements SessionManager, UserListener, VisibilityChangeListener {
  public static EMPTY_SESSION_ID = "0.ffffffff"

  private sessionListeners: SessionListener[] = []

  private _currentSessionId: string | null = null
  private _lastEventTime: number | null = null

  constructor(private sessionTimeoutMillis: number, private storage: IStorage) {
    this.loadSession()
    this.loadLastEventTime()
  }

  public get currentSessionId(): string | null {
    return this._currentSessionId
  }

  public get sessionId(): string {
    return this._currentSessionId ?? SessionManagerImpl.EMPTY_SESSION_ID
  }

  public get lastEventTime(): number | null {
    return this._lastEventTime
  }

  public addListener(listener: SessionListener): void {
    this.sessionListeners.push(listener)
  }

  public startNewSession(previousUser: User | null, user: User, timestamp: number): string {
    if (previousUser) {
      this.endSession(previousUser)
    }

    return this.newSession(user, timestamp)
  }

  public startNewSessionIfNeeded(user: User, timestamp: number): string {
    let lastEventTime = this._lastEventTime

    if (lastEventTime === null) {
      return this.startNewSession(user, user, timestamp)
    }

    if (this._currentSessionId !== null && timestamp - lastEventTime < this.sessionTimeoutMillis) {
      this.updateLastEventTime(timestamp)
      return this._currentSessionId
    }

    return this.startNewSession(user, user, timestamp)
  }

  public updateLastEventTime(timestamp: number): void {
    this._lastEventTime = timestamp
    this.storage.setItem(LAST_EVENT_TIME_STORAGE_KEY, `${timestamp}`)
  }

  private endSession(user: User) {
    const currentSessionId = this._currentSessionId
    const lastEventTime = this._lastEventTime
    if (currentSessionId !== null && lastEventTime !== null) {
      this.sessionListeners.forEach((listener) => listener.onSessionEnded(currentSessionId, user, lastEventTime))
    }
  }

  private newSession(user: User, timestamp: number): string {
    const newSessionId = createSessionId(timestamp)
    this.saveSession(newSessionId)

    this.updateLastEventTime(timestamp)

    this.sessionListeners.forEach((listener) => {
      listener.onSessionStarted(newSessionId, user, timestamp)
    })

    return newSessionId
  }

  private saveSession(sessionId: string) {
    this._currentSessionId = sessionId
    this.storage.setItem(SESSION_ID_STORAGE_KEY, sessionId)
  }

  private loadSession() {
    this._currentSessionId = this.storage.getItem(SESSION_ID_STORAGE_KEY)
  }

  private loadLastEventTime() {
    const lastEventTimeStr = this.storage.getItem(LAST_EVENT_TIME_STORAGE_KEY)

    if (lastEventTimeStr !== null) {
      const lastEventTime = Number.parseFloat(lastEventTimeStr)

      if (lastEventTime > 0) {
        this._lastEventTime = lastEventTime
      }
    }
  }

  onUserUpdated(oldUser: User, newUser: User, timestamp: number): void {
    this.endSession(oldUser)
    this.newSession(newUser, timestamp)
  }

  onVisibilityChanged(visibility: Visibility, timestamp: number): void {
    switch (visibility) {
      case "visible":
        return
      case "hidden":
        this.updateLastEventTime(timestamp)
        return
    }
  }
}
