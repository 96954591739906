import { HackleUser, InAppMessage, InAppMessageContextMessage, Properties } from "../../../core/internal/model/model"

export class InAppMessagePresentationContext {
  constructor(
    public readonly inAppMessage: InAppMessage,
    public readonly message: InAppMessageContextMessage,
    public readonly user: HackleUser,
    public readonly properties: Properties
  ) {}
}

export interface InAppMessagePresenter {
  present(context: InAppMessagePresentationContext): void
}
