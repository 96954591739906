import InvocationResponseResolver from "./InvocationResponseResolver"

export interface InvocationRequestDto {
  _hackle: {
    command: string
    parameters?: Record<string, any>
  }
}

export interface InvocationResponseDto<T = any> {
  success: boolean
  message: string
  data?: T
}

export interface HackleAppInvocator {
  invoke<R>(invocationRequestDto: InvocationRequestDto): InvocationResponseDto<R>
}

export class PromptAppInvocator implements HackleAppInvocator {
  constructor(private readonly invocationResponseResolver: InvocationResponseResolver) {}

  invoke<R>(invocationRequestDto: InvocationRequestDto): InvocationResponseDto<R> {
    const result = window.prompt(JSON.stringify(invocationRequestDto))
    return this.invocationResponseResolver.resolve(result)
  }
}

export class FunctionAppInvocator implements HackleAppInvocator {
  constructor(private readonly invocationResponseResolver: InvocationResponseResolver) {}

  invoke<R>(invocationRequestDto: InvocationRequestDto): InvocationResponseDto<R> {
    if (!window._hackleApp?.invoke) {
      throw new Error("window._hackleApp.invoke is not implemented")
    }

    const result = window._hackleApp.invoke(JSON.stringify(invocationRequestDto))
    return this.invocationResponseResolver.resolve(result)
  }
}
