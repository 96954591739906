import { Transport, TransportHeaders, TransportRequest, TransportResponse } from "./Transport"

export class XhrTransport implements Transport {
  static create(): XhrTransport {
    return new XhrTransport()
  }

  send(request: TransportRequest): Promise<TransportResponse> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      if (request.options.timeoutMillis) {
        xhr.timeout = request.options.timeoutMillis
      }

      xhr.open(request.method, request.url)
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== xhr.DONE) {
          return
        }
        if (xhr.status === 0) {
          reject(new Error("Request Error"))
          return
        }
        const response = handleResponse(xhr)
        resolve(response)
      }

      setRequestHeaders(xhr, request)
      xhr.send(request.body)
    })
  }
}

function setRequestHeaders(xhr: XMLHttpRequest, request: TransportRequest) {
  const headers = request.headers.raw()
  for (const name in headers) {
    if (headers.hasOwnProperty(name)) {
      xhr.setRequestHeader(name, headers[name])
    }
  }
}

function handleResponse(xhr: XMLHttpRequest): TransportResponse {
  return new TransportResponse(xhr.status, xhr.responseText, responseHeaderFromXhr(xhr))
}

function responseHeaderFromXhr(xhr: XMLHttpRequest): TransportHeaders {
  const builder = TransportHeaders.builder()
  const responseHeaders = xhr.getAllResponseHeaders()
  if (!responseHeaders) {
    return builder.build()
  }

  for (let header of responseHeaders.split("\r\n")) {
    const index = header.indexOf(": ")
    if (index > 0) {
      const name = header.slice(0, index)
      const value = header.slice(index + 2)
      builder.add(name, value)
    }
  }
  return builder.build()
}
