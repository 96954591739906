export class InAppMessageRenderScriptLoader {
  constructor(private readonly scriptUrl: string) {}

  async load() {
    if ((window as any).HackleInAppMessageRenderer) {
      return
    }

    const script = this.createScript()
    document.head.appendChild(script)

    return await this.waitForLoad(script)
  }

  private createScript() {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src = this.scriptUrl

    if ((process.env.NODE_ENV as string | undefined) === "test") {
      script.setAttribute("data-testid", "renderer-script")
    }

    return script
  }

  private waitForLoad<T extends HTMLElement>(el: T) {
    return new Promise<T>((resolve, reject) => {
      const handleLoad = () => {
        cleanup()
        resolve(el)
      }

      const handleError = (ev: ErrorEvent) => {
        cleanup()
        reject(ev.message)
      }

      const cleanup = () => {
        el.removeEventListener("error", handleError)
        el.removeEventListener("load", handleLoad)
      }

      el.addEventListener("load", handleLoad)
      el.addEventListener("error", handleError)
    })
  }
}
