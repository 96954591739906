import { ScheduledJob, Scheduler } from "./Scheduler"

export class TimeoutScheduler implements Scheduler {
  schedule(delayMillis: number, task: () => void): ScheduledJob {
    const job = new TimeoutScheduledJob(delayMillis, task)
    job.start()
    return job
  }

  schedulePeriodically(delayMillis: number, intervalMillis: number, task: () => void): ScheduledJob {
    const job = new TimeoutPeriodicScheduledJob(delayMillis, intervalMillis, task)
    job.start()
    return job
  }
}

class TimeoutScheduledJob implements ScheduledJob {
  private timeout: ReturnType<typeof setTimeout> | null

  constructor(private readonly delayMillis: number, private readonly task: () => void) {
    this.timeout = null
  }

  start() {
    this.timeout = setTimeout(() => this.task(), this.delayMillis)
  }

  cancel(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }
}

class TimeoutPeriodicScheduledJob implements ScheduledJob {
  private timeout: ReturnType<typeof setTimeout> | null

  constructor(
    private readonly delayMillis: number,
    private readonly intervalMillis: number,
    private readonly task: () => void
  ) {
    this.timeout = null
  }

  start() {
    this.timeout = setTimeout(() => this.run(), this.delayMillis)
  }

  private run(): void {
    this.task()
    this.timeout = setTimeout(() => this.run(), this.intervalMillis)
  }

  cancel(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }
}
