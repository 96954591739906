import { UserEventEmitter } from "../../core/internal/event/UserEventEmitter"
import { SplitUrlDeterminer } from "./SplitUrlDeterminer"
import { SplitUrlRedirector } from "./SplitUrlRedirector"
import UserEvent from "../../core/internal/event/UserEvent"
import HackleCore from "../../core/HackleCore"

export class SplitUrlManager {
  constructor(
    private readonly userEventEmitter: UserEventEmitter,
    private readonly determiner: SplitUrlDeterminer,
    private readonly redirector: SplitUrlRedirector,
    private readonly core: HackleCore
  ) {
    this.initialize()
  }

  private initialize() {
    this.userEventEmitter.on("event", this.onEvent)
  }

  public destroy() {
    this.userEventEmitter.off("event", this.onEvent)
  }

  private onEvent = (event: UserEvent): void => {
    const context = this.determiner.determineOrNull(event)
    if (!context) return
    this.core.flush(true)
    this.redirector.redirect(context)
  }
}
