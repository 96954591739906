import { UserEventDedupDeterminer } from "./UserEventDedupDeterminer"
import UserEvent from "../../../core/internal/event/UserEvent"
import { CachedUserEventDeterminer } from "./CachedUserEventDeterminer"

export class DelegatingUserEventDedupDeterminer implements UserEventDedupDeterminer {
  constructor(private readonly determiners: CachedUserEventDeterminer<UserEvent>[]) {}

  isDedupTarget(event: UserEvent): boolean {
    const determiner = this.determiners.find((it) => it.supports(event))
    if (!determiner) return false
    return determiner.isDedupTarget(event)
  }
}
