import { Strings } from "../util/Strings"

export interface Transport {
  send(request: TransportRequest): Promise<TransportResponse>
}

export type TransportMethod = "GET" | "POST"

export class TransportRequest {
  constructor(
    readonly url: string,
    readonly method: TransportMethod,
    readonly body: string | null,
    readonly headers: TransportHeaders,
    readonly options: TransportOptions
  ) {}

  static builder(): TransportRequestBuilder {
    return new TransportRequestBuilder()
  }

  static get(url: string): TransportRequest {
    return TransportRequest.builder().url(url).build()
  }

  toBuilder(): TransportRequestBuilder {
    return new TransportRequestBuilder(this)
  }
}

export class TransportRequestBuilder {
  private _url: string | null
  private _method: TransportMethod
  private _body: string | null
  private _headers: TransportHeadersBuilder
  private _timeoutMillis: number | null

  constructor(request?: TransportRequest) {
    this._url = request?.url ?? null
    this._method = request?.method ?? "GET"
    this._body = request?.body ?? null
    this._headers = request?.headers?.toBuilder() ?? TransportHeaders.builder()
    this._timeoutMillis = request?.options?.timeoutMillis ?? null
  }

  url(url: string): TransportRequestBuilder {
    this._url = url
    return this
  }

  method(method: TransportMethod): TransportRequestBuilder {
    this._method = method
    return this
  }

  body(body: string): TransportRequestBuilder {
    this._body = body
    return this
  }

  addHeader(name: string, value: string): TransportRequestBuilder {
    this._headers.add(name, value)
    return this
  }

  timeoutMillis(timeoutMillis: number): TransportRequestBuilder {
    this._timeoutMillis = timeoutMillis
    return this
  }

  build(): TransportRequest {
    if (this._url === null) {
      throw new Error("url is null")
    }
    const options: TransportOptions = { timeoutMillis: this._timeoutMillis }
    return new TransportRequest(this._url, this._method, this._body, this._headers.build(), options)
  }
}

export class TransportResponse {
  constructor(readonly statusCode: number, readonly body: string | null, readonly headers: TransportHeaders) {}

  static ok(body?: string, headers?: TransportHeaders): TransportResponse {
    return TransportResponse.of(200, body, headers)
  }

  static of(statusCode: number, body?: string, headers?: TransportHeaders): TransportResponse {
    return new TransportResponse(statusCode, body ?? null, headers ?? TransportHeaders.empty())
  }

  isSuccessful(): boolean {
    return this.statusCode >= 200 && this.statusCode < 300
  }

  isClientError(): boolean {
    return this.statusCode >= 400 && this.statusCode < 500
  }

  isNotModified(): boolean {
    return this.statusCode === 304
  }
}

export interface TransportOptions {
  readonly timeoutMillis: number | null
}

export class TransportHeaders {
  constructor(private readonly headers: Map<string, string>) {}

  static empty(): TransportHeaders {
    return new TransportHeaders(new Map())
  }

  static builder(): TransportHeadersBuilder {
    return new TransportHeadersBuilder(new Map())
  }

  get(name: string): string | null {
    for (let [headerName, headerValue] of Array.from(this.headers.entries())) {
      if (Strings.equalsIgnoreCase(headerName, name)) {
        return headerValue
      }
    }
    return null
  }

  getOrDefault(name: string, defaultValue: string): string {
    return this.get(name) ?? defaultValue
  }

  raw(): Record<string, string> {
    return Array.from(this.headers.entries()).reduce<Record<string, string>>((record, [name, value]) => {
      record[name] = value
      return record
    }, {})
  }

  toBuilder(): TransportHeadersBuilder {
    return new TransportHeadersBuilder(this.headers)
  }

  static IF_MODIFIED_SINCE = "If-Modified-Since"
  static LAST_MODIFIED = "Last-Modified"
}

export class TransportHeadersBuilder {
  constructor(private readonly headers: Map<string, string>) {}

  add(name: string, value: string): TransportHeadersBuilder {
    this.headers.set(name, value)
    return this
  }

  build(): TransportHeaders {
    return new TransportHeaders(this.headers)
  }
}
