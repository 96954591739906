import { HackleAppInvocator } from "../../core/internal/invocator/HackleAppInvocator"
import { InvocationRequestDto } from "../../core/internal/invocator/HackleAppInvocator"
import { DecisionReason, Properties, User, VariationKey } from "../../core/internal/model/model"

export class HackleAppInvocationProcessor {
  constructor(private readonly invocator: HackleAppInvocator) {}

  private createInvocationRequestDto<P extends Record<string, any> | never, R>({
    command,
    parameters
  }: Invocation<P, R>): InvocationRequestDto {
    return { _hackle: { command, parameters } }
  }

  process<P extends Record<string, any> | never, R>(invocation: Invocation<P, R>): R | null {
    const request = this.createInvocationRequestDto(invocation)
    return this.invocator.invoke<R>(request).data ?? null
  }
}

type InvocationProcessCommand =
  | "getUser"
  | "getSessionId"
  | "setUser"
  | "setUserId"
  | "setDeviceId"
  | "setUserProperty"
  | "updateUserProperties"
  | "resetUser"
  | "variation"
  | "variationDetail"
  | "isFeatureOn"
  | "featureFlagDetail"
  | "track"
  | "showUserExplorer"
  | "remoteConfig"

export interface SetUserInvocationDto {
  user: User
}

export interface SetUserIdInvocationDto {
  userId: string | null
}

export interface SetDeviceIdInvocationDto {
  deviceId: string
}

export interface SetUserPropertyInvocationDto {
  key: string
  value: any
}

export interface UpdateUserPropertiesInvocationDto {
  operations: Record<string, any>
}

export interface VariationInvocationDto {
  experimentKey: number
  user?: User | string
  defaultVariation?: VariationKey
}

export interface FeatureFlagInvocationDto {
  featureKey: number
  user?: User | string
}

export interface TrackInvocationDto {
  event: {
    key: string
    value?: number
    properties?: Properties<string>
  }
  user?: User | string
}

export interface RemoteConfigInvocationDto {
  key: string
  valueType: string
  defaultValue: string | number | boolean
  user?: User | string
}

export interface InvocationDecisionDto {
  variation: VariationKey
  reason: DecisionReason
  config: {
    parameters: Record<string, any>
  }
}

export interface InvocationFeatureFlagDecisionDto {
  isOn: boolean
  reason: DecisionReason
  config: {
    parameters: Record<string, any>
  }
}

export interface Invocation<P extends Record<string, any> | never, R> {
  readonly command: InvocationProcessCommand
  readonly parameters?: P
}

export interface GetUserInvocation extends Invocation<never, User> {
  readonly command: "getUser"
}

export interface GetSessionIdInvocation extends Invocation<never, string> {
  readonly command: "getSessionId"
}

export interface SetUserInvocation extends Invocation<SetUserInvocationDto, void> {
  readonly command: "setUser"
  readonly parameters: SetUserInvocationDto
}

export interface SetUserIdInvocation extends Invocation<SetUserIdInvocationDto, void> {
  readonly command: "setUserId"
  readonly parameters: SetUserIdInvocationDto
}

export interface SetDeviceIdInvocation extends Invocation<SetDeviceIdInvocationDto, void> {
  readonly command: "setDeviceId"
  readonly parameters: SetDeviceIdInvocationDto
}

export interface SetUserPropertyInvocation extends Invocation<SetUserPropertyInvocationDto, void> {
  readonly command: "setUserProperty"
  readonly parameters: SetUserPropertyInvocationDto
}

export interface UpdateUserPropertiesInvocation extends Invocation<UpdateUserPropertiesInvocationDto, void> {
  readonly command: "updateUserProperties"
  readonly parameters: UpdateUserPropertiesInvocationDto
}

export interface ResetUserInvocation extends Invocation<never, void> {
  readonly command: "resetUser"
}

export interface VariationInvocation extends Invocation<VariationInvocationDto, string> {
  readonly command: "variation"
  readonly parameters: VariationInvocationDto
}

export interface VariationDetailInvocation extends Invocation<VariationInvocationDto, InvocationDecisionDto> {
  readonly command: "variationDetail"
  readonly parameters: VariationInvocationDto
}

export interface IsFeatureOnInvocation extends Invocation<FeatureFlagInvocationDto, boolean> {
  readonly command: "isFeatureOn"
  readonly parameters: FeatureFlagInvocationDto
}

export interface FeatureFlagDetailInvocation
  extends Invocation<FeatureFlagInvocationDto, InvocationFeatureFlagDecisionDto> {
  readonly command: "featureFlagDetail"
  readonly parameters: FeatureFlagInvocationDto
}

export interface TrackInvocation extends Invocation<TrackInvocationDto, void> {
  readonly command: "track"
  readonly parameters: TrackInvocationDto
}

export interface ShowUserExplorerInvocation extends Invocation<never, void> {
  readonly command: "showUserExplorer"
}

export interface RemoteConfigInvocation extends Invocation<RemoteConfigInvocationDto, string | number | boolean> {
  readonly command: "remoteConfig"
}
