import { CachedUserEventDeterminer } from "./CachedUserEventDeterminer"
import UserEvent, { ExposureEvent } from "../../../core/internal/event/UserEvent"
import { Clock, SystemClock } from "../../../core/internal/util/TimeUtil"

export class ExposureEventDedupDeterminer extends CachedUserEventDeterminer<ExposureEvent> {
  constructor(dedupIntervalMillis: number, clock: Clock = SystemClock.instance) {
    super(dedupIntervalMillis, clock)
  }

  supports(event: UserEvent): event is ExposureEvent {
    return UserEvent.isExposureEvent(event)
  }

  cacheKey(event: ExposureEvent): string {
    return [event.experiment.id, event.variationId, event.variationKey, event.decisionReason].join("-")
  }
}
