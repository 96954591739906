export class DomainUtil {
  private static DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i
  private static SIMPLE_DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]*\.[a-z]+$/i

  static extractDomain(hostname: string): string {
    let domain_regex = this.DOMAIN_MATCH_REGEX

    const parts = hostname.split(".")
    const tld = parts[parts.length - 1]
    if (tld.length > 4 || tld === "com" || tld === "org") {
      domain_regex = this.SIMPLE_DOMAIN_MATCH_REGEX
    }

    const matches = hostname.match(domain_regex)
    return matches ? matches[0] : hostname
  }
}
