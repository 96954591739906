export interface IStorage {
  getItem(key: string): string | null

  setItem(key: string, value: string): void

  removeItem(key: string): void
}

export class WindowLocalStorage implements IStorage {
  private storage: Storage | null // should be nullable because of Webkit browsers and Webview etc.

  constructor() {
    this.storage = window.localStorage
  }

  getItem(key: string): string | null {
    return this.storage?.getItem(key) ?? null
  }

  removeItem(key: string): void {
    this.storage?.removeItem(key)
  }

  setItem(key: string, value: string): void {
    this.storage?.setItem(key, value)
  }

  clear() {
    this.storage?.clear()
  }
}

export class KeyTransformStorage implements IStorage {
  private readonly delegate: IStorage
  private readonly transform: (key: string) => string

  constructor(delegate: IStorage, transform: (key: string) => string) {
    this.delegate = delegate
    this.transform = transform
  }

  static postfix(delegate: IStorage, postfix: string): KeyTransformStorage {
    return new KeyTransformStorage(delegate, (key) => `${key}${postfix}`)
  }

  static prefix(delegate: IStorage, prefix: string): KeyTransformStorage {
    return new KeyTransformStorage(delegate, (key) => `${prefix}${key}`)
  }

  getItem(key: string): string | null {
    return this.delegate.getItem(this.transform(key))
  }

  removeItem(key: string): void {
    this.delegate.removeItem(this.transform(key))
  }

  setItem(key: string, value: string): void {
    this.delegate.setItem(this.transform(key), value)
  }
}

type Item<T> = Record<string, T>

export class ListStorage<Value = string> {
  private maxLength
  private encodedListCharLen = 6
  private resolveMaxLength = (maxLength: number) => {
    const len = maxLength - this.encodedListCharLen
    return len < 0 ? 0 : len
  }

  constructor(private delegate: IStorage, private listKey: string, maxLength: number = 4096) {
    this.maxLength = this.resolveMaxLength(maxLength)
  }

  private isEqualItem(item: Item<Value>, key: string) {
    return Object.prototype.hasOwnProperty.call(item, key)
  }

  private getRemainListSize() {
    try {
      return this.maxLength - JSON.stringify(this.getList()).length
    } catch (error) {
      return 0
    }
  }

  private isAddableItem(item: Item<Value>) {
    try {
      return this.getRemainListSize() > JSON.stringify(item).length
    } catch (err) {
      return false
    }
  }

  private getVictimIndex(nextItem: Item<Value>): number {
    const remainListSize = this.getRemainListSize()

    try {
      const _nextItem = JSON.stringify(nextItem)
      const list = this.getList()
      let cum = 0

      return list.findIndex((item) => {
        cum += JSON.stringify(item).length

        return cum + remainListSize >= _nextItem.length
      })
    } catch (error) {
      return -1
    }
  }

  getList(): Item<Value>[] {
    try {
      const item = this.delegate.getItem(this.listKey)
      if (!item) return []

      const parsedItem = JSON.parse(item)
      if (!Array.isArray(parsedItem)) return []

      return parsedItem
    } catch (error) {
      return []
    }
  }

  entries(): [string, Value][] {
    return this.getList().flatMap(Object.entries)
  }

  setItem(key: string, value: Value): void {
    try {
      this.removeItem(key)
      let items = this.getList()
      const nextItem: Item<Value> = { [key]: value }

      if (!this.isAddableItem(nextItem)) {
        const victimIndex = this.getVictimIndex(nextItem)
        if (victimIndex < 0) return

        items = items.slice(victimIndex + 1)
      }

      const nextItems = JSON.stringify(items.concat(nextItem))
      this.delegate.setItem(this.listKey, nextItems)
    } catch (error) {}
  }

  getItem(key: string): Value | undefined {
    const item = this.getList().find((item) => this.isEqualItem(item, key))
    if (!item || !item[key]) return undefined

    return item[key]
  }

  removeItem(key: string): void {
    const items = this.getList()

    try {
      const removedItems = items.filter((item) => !this.isEqualItem(item, key))
      const nextItems = JSON.stringify(removedItems)
      this.delegate.setItem(this.listKey, nextItems)
    } catch (error) {}
  }

  clear() {
    this.delegate.removeItem(this.listKey)
  }
}
