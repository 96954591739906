import { Sdk } from "../model/model"
import { Transport } from "./Transport"
import { SdkHeaderInterceptor } from "./SdkHeaderInterceptor"
import { SystemClock } from "../util/TimeUtil"

export class Transports {
  static intercept(sdk: Sdk, transport: Transport): Transport {
    if (transport instanceof SdkHeaderInterceptor) {
      return transport
    }
    return new SdkHeaderInterceptor(sdk, SystemClock.instance, transport)
  }
}
