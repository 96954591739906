import { Properties } from "../../core/internal/model/model"
import { Strings } from "../../core/internal/util/Strings"

export interface UtmProperties extends Properties {
  utm_campaign: string | undefined
  utm_content: string | undefined
  utm_id: string | undefined
  utm_medium: string | undefined
  utm_source: string | undefined
  utm_term: string | undefined
}

export interface ClickIdProperties extends Properties {
  gclid: string | undefined
  fbclid: string | undefined
}

export interface Campaign extends UtmProperties, ClickIdProperties, Properties {}

export const EMPTY_CAMPAIGN: Campaign = {
  utm_campaign: undefined,
  utm_content: undefined,
  utm_id: undefined,
  utm_medium: undefined,
  utm_source: undefined,
  utm_term: undefined,

  gclid: undefined,
  fbclid: undefined
}

export interface CampaignDomain {
  current: string | undefined
  referrer: string | undefined
}

export const EMPTY_DOMAIN: CampaignDomain = {
  current: undefined,
  referrer: undefined
}

export const isEmptyCampaign = (campaign: Campaign): boolean => {
  return isSameCampaign(campaign, EMPTY_CAMPAIGN)
}

export const isSameCampaign = (a: Campaign, b: Campaign): boolean => {
  return Object.keys(EMPTY_CAMPAIGN).every((key) => a[key] === b[key])
}

export const isNewCampaign = (
  previousCampaign: Campaign | null,
  newCampaign: Campaign,
  domain: CampaignDomain
): boolean => {
  if (previousCampaign === null) {
    return true
  }

  if (isEmptyCampaign(newCampaign) && domain.current === domain.referrer) {
    return false
  }

  return !isSameCampaign(previousCampaign, newCampaign)
}

export const setCampaignToUrl = (campaign: Campaign, url: URL): URL => {
  for (const [key, value] of Object.entries(campaign)) {
    if (Strings.isNotEmptyString(value)) {
      url.searchParams.set(key, value)
    }
  }
  return url
}
