import { Config, Long } from "../model/model"
import ObjectUtil from "../util/ObjectUtil"

/**
 * An interface model for Parameter Config
 */
export interface ParameterConfig extends Config {}

export class EmptyParameterConfig implements ParameterConfig {
  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    return defaultValue
  }
}

export class DefaultParameterConfig implements ParameterConfig {
  constructor(private readonly parameters: Map<string, any>) {}

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    const parameterValue = this.parameters.get(key)

    if (ObjectUtil.isNullOrUndefined(parameterValue)) {
      return defaultValue
    }

    if (ObjectUtil.isNullOrUndefined(defaultValue)) {
      return parameterValue
    }

    if (typeof parameterValue === typeof defaultValue) {
      return parameterValue
    }

    return defaultValue
  }
}
