export class Comparable<K, V = K> {
  private data: Map<K, V> = new Map()

  constructor(private readonly compareFn: (a: K, b: K) => boolean) {
    this.compareFn = compareFn
    this.data = new Map()
  }

  values() {
    return Array.from(this.data.values())
  }

  keys() {
    return Array.from(this.data.keys())
  }

  get(key: K): V | undefined {
    const targetKey = this.keys().find((innerKey) => this.compareFn(innerKey, key))
    return targetKey ? this.data.get(targetKey) : undefined
  }

  has(key: K): boolean {
    return this.get(key) !== undefined
  }

  add(key: K, value?: V): this {
    let addValue = value
    if (!addValue) {
      addValue = key as unknown as V
    }

    this.data.set(key, addValue)
    return this
  }

  delete(key: K): V | undefined {
    if (!this.has(key)) return undefined

    const targetValue = this.get(key)
    this.data.delete(key)

    return targetValue
  }

  forEach(cb: (value: V, key: K) => void) {
    this.data.forEach(cb)
  }

  filter(predicate: (key: K, value: V) => boolean): Comparable<K, V> {
    const destination = new Comparable<K, V>(this.compareFn)
    for (const kv of this.entries()) {
      if (predicate(kv[0], kv[1])) {
        destination.add(kv[0], kv[1])
      }
    }
    return destination
  }

  entries() {
    return Array.from(this.data.entries())
  }
}
