import { Double, Identifiers, InAppMessage } from "../../../core/internal/model/model"
import { IStorage } from "../../../core/internal/storage/Storage"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"

/**
 * This interface is serialized and deserialized to JSON.
 * Please be careful of field changes.
 */
export interface InAppMessageImpression {
  identifiers: Identifiers
  timestamp: Double
}

export class InAppMessageImpressionStorage {
  constructor(private readonly storage: IStorage) {}

  get(inAppMessage: InAppMessage): InAppMessageImpression[] {
    const impressions = this.storage.getItem(inAppMessage.id.toString())
    if (ObjectUtil.isNullOrUndefined(impressions)) return []
    return JSON.parse(impressions)
  }

  set(inAppMessage: InAppMessage, impressions: InAppMessageImpression[]): void {
    this.storage.setItem(inAppMessage.id.toString(), JSON.stringify(impressions))
  }
}
