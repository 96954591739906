import { Clock, SystemClock } from "../util/TimeUtil"

export type Visibility = "visible" | "hidden"

export class VisibilityManager {
  private readonly listeners: VisibilityChangeListener[] = []

  constructor(private readonly clock: Clock = SystemClock.instance) {}

  addListener(listener: VisibilityChangeListener): void {
    this.listeners.push(listener)
  }

  changeVisibility(visibility: Visibility): void {
    const timestamp = this.clock.currentMillis()
    this.listeners.forEach((listener) => {
      listener.onVisibilityChanged(visibility, timestamp)
    })
  }
}

export interface VisibilityChangeListener {
  /**
   * The same visibility event can occur continuously
   * e.g. pagehide -> visibilitychange(hidden)
   *      pageshow -> visibilitychange(visible)
   */
  onVisibilityChanged(visibility: Visibility, timestamp: number): void
}
