import { EvaluatorKey, EvaluatorRequest } from "../Evaluator"
import Workspace from "../../../workspace/Workspace"
import { HackleUser, InAppMessage } from "../../../model/model"

export class InAppMessageRequest implements EvaluatorRequest {
  readonly key: EvaluatorKey
  readonly workspace: Workspace
  readonly user: HackleUser
  readonly inAppMessage: InAppMessage
  readonly timestamp: number

  constructor(workspace: Workspace, user: HackleUser, inAppMessage: InAppMessage, timestamp: number) {
    this.key = new EvaluatorKey("IN_APP_MESSAGE", inAppMessage.id)
    this.workspace = workspace
    this.user = user
    this.inAppMessage = inAppMessage
    this.timestamp = timestamp
  }

  static of(
    workspace: Workspace,
    user: HackleUser,
    inAppMessage: InAppMessage,
    timestamp: number
  ): InAppMessageRequest {
    return new InAppMessageRequest(workspace, user, inAppMessage, timestamp)
  }

  toString(): string {
    return `EvaluatorRequest(type=${this.key.type}, key=${this.inAppMessage.key})`
  }
}
