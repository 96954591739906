import UserEvent from "../../core/internal/event/UserEvent"
import { SplitUrlContext } from "./SplitUrlContext"
import WorkspaceFetcher from "../../core/internal/workspace/WorkspaceFetcher"
import { SplitUrlMatcher } from "./SplitUrlMatcher"
import HackleCore from "../../core/HackleCore"
import { SplitUrlRedirectedCookieStorage } from "./SplitUrlRedirectedCookieStorage"

export class SplitUrlDeterminer {
  constructor(
    private readonly workspaceFetcher: WorkspaceFetcher,
    private readonly splitUrlMatcher: SplitUrlMatcher,
    private readonly splitUrlRedirectedCookieStorage: SplitUrlRedirectedCookieStorage,
    private readonly core: HackleCore
  ) {}

  determineOrNull(event: UserEvent): SplitUrlContext | null {
    if (!UserEvent.isTrackEvent(event)) return null
    if (event.event.key !== "$page_view") return null

    if (this.splitUrlRedirectedCookieStorage.alreadyRedirected()) {
      this.splitUrlRedirectedCookieStorage.clear()
      return null
    }

    const workspace = this.workspaceFetcher.get()
    if (!workspace) return null

    for (const experiment of workspace.getSplitUrlExperiments()) {
      const isMatch = this.splitUrlMatcher.matches(workspace, experiment, event)
      if (isMatch) {
        const decision = this.core.experiment(experiment.key, event.user, "A")
        return new SplitUrlContext(workspace, experiment, decision, event)
      }
    }

    return null
  }
}
