import { InAppMessage, InAppMessageContextMessage, Properties } from "../../../model/model"
import { EvaluatorContext, EvaluatorEvaluation } from "../Evaluator"
import { InAppMessageRequest } from "./InAppMessageRequest"

export class InAppMessageEvaluation implements EvaluatorEvaluation {
  constructor(
    public readonly reason: string,
    public readonly targetEvaluations: EvaluatorEvaluation[],
    public readonly inAppMessage: InAppMessage,
    public readonly message: InAppMessageContextMessage | undefined,
    public readonly properties: Properties
  ) {}

  static of(
    request: InAppMessageRequest,
    context: EvaluatorContext,
    reason: string,
    message?: InAppMessageContextMessage
  ): InAppMessageEvaluation {
    return new InAppMessageEvaluation(
      reason,
      context.targetEvaluations,
      request.inAppMessage,
      message,
      context.properties
    )
  }
}
