import { HackleAppInvocator } from "./HackleAppInvocator"
import { FunctionAppInvocator, PromptAppInvocator } from "./HackleAppInvocator"
import InvocationResponseResolver from "./InvocationResponseResolver"

enum InvocationType {
  prompt = "prompt",
  function = "function"
}

export default class InvocatorFactory {
  getInvocator(
    invocationType: string,
    invocationResponseResolver: InvocationResponseResolver
  ): HackleAppInvocator | null {
    if (invocationType === InvocationType.prompt) {
      return new PromptAppInvocator(invocationResponseResolver)
    }

    if (invocationType === InvocationType.function) {
      return new FunctionAppInvocator(invocationResponseResolver)
    }

    return null
  }
}
