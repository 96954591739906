import { EvaluatorContext, EvaluatorEvaluation, EvaluatorRequest } from "../evalautor/Evaluator"
import FlowEvaluator from "./FlowEvaluator"

export default class EvaluationFlow<Request extends EvaluatorRequest, Evaluation extends EvaluatorEvaluation> {
  readonly flowEvaluator: FlowEvaluator<Request, Evaluation> | undefined
  readonly nextFlow: EvaluationFlow<Request, Evaluation> | undefined

  private constructor(
    flowEvaluator: FlowEvaluator<Request, Evaluation> | undefined,
    nextFlow: EvaluationFlow<Request, Evaluation> | undefined
  ) {
    this.flowEvaluator = flowEvaluator
    this.nextFlow = nextFlow
  }

  evaluate(request: Request, context: EvaluatorContext): Evaluation | undefined {
    if (this.flowEvaluator && this.nextFlow) {
      return this.flowEvaluator.evaluate(request, context, this.nextFlow)
    } else {
      return undefined
    }
  }

  static end<Request extends EvaluatorRequest, Evaluation extends EvaluatorEvaluation>(): EvaluationFlow<
    Request,
    Evaluation
  > {
    return new EvaluationFlow<Request, Evaluation>(undefined, undefined)
  }

  static decision<Request extends EvaluatorRequest, Evaluation extends EvaluatorEvaluation>(
    flowEvaluator: FlowEvaluator<Request, Evaluation>,
    nextFlow: EvaluationFlow<Request, Evaluation>
  ): EvaluationFlow<Request, Evaluation> {
    return new EvaluationFlow<Request, Evaluation>(flowEvaluator, nextFlow)
  }

  static of<Request extends EvaluatorRequest, Evaluation extends EvaluatorEvaluation>(
    ...evaluators: FlowEvaluator<Request, Evaluation>[]
  ): EvaluationFlow<Request, Evaluation> {
    let flow: EvaluationFlow<Request, Evaluation> = this.end()
    for (const evaluator of evaluators.reverse()) {
      flow = this.decision(evaluator, flow)
    }
    return flow
  }
}
