import ObjectUtil from "./ObjectUtil"

export class Strings {
  static equalsIgnoreCase(a: string, b: string): boolean {
    return a.toLowerCase() === b.toLowerCase()
  }

  static isNotEmptyString(value: any): value is string {
    if (ObjectUtil.isNullOrUndefined(value)) {
      return false
    }
    if (typeof value !== "string") {
      return false
    }
    return value.length > 0
  }
}
