import { InAppMessageView } from "../view/InAppMessageView"
import {
  InAppMessageActionInteraction,
  InAppMessageCloseInteraction,
  InAppMessageInteraction
} from "./InAppMessageInteraction"
import { InAppMessageEventHandler } from "./InAppMessageEventHandler"
import { InAppMessageAction } from "../../../../core/internal/model/model"
import { InAppMessageActionEvent } from "./InAppMessageEvent"

export interface InAppMessageInteractionHandler<Interaction extends InAppMessageInteraction> {
  supports(interaction: InAppMessageInteraction): boolean

  handle(view: InAppMessageView, interaction: Interaction): void
}

export class InAppMessageInteractionHandlerFactory {
  constructor(private readonly handlers: InAppMessageInteractionHandler<InAppMessageInteraction>[]) {}

  get(interaction: InAppMessageInteraction): InAppMessageInteractionHandler<InAppMessageInteraction> | undefined {
    return this.handlers.find((it) => it.supports(interaction))
  }
}

export class InAppMessageCloseInteractionHandler
  implements InAppMessageInteractionHandler<InAppMessageCloseInteraction>
{
  handle(view: InAppMessageView, interaction: InAppMessageCloseInteraction): void {
    view.close()
  }

  supports(interaction: InAppMessageInteraction): boolean {
    return interaction.type === "CLOSE"
  }
}

export class InAppMessageActionInteractionHandler
  implements InAppMessageInteractionHandler<InAppMessageActionInteraction>
{
  constructor(private readonly eventHandler: InAppMessageEventHandler) {}

  handle(view: InAppMessageView, interaction: InAppMessageActionInteraction): void {
    const action = InAppMessageAction.fromJson(interaction.action)
    if (!action) return

    const event: InAppMessageActionEvent = {
      type: "ACTION",
      action,
      area: interaction.area,
      text: interaction.text
    }
    this.eventHandler.handle(view, event)
  }

  supports(interaction: InAppMessageInteraction): boolean {
    return interaction.type === "ACTION"
  }
}
