import { Transport, TransportRequest, TransportResponse } from "./Transport"
import { Sdk } from "../model/model"
import { Clock } from "../util/TimeUtil"
import { SDK_KEY_HEADER, SDK_NAME_HEADER, SDK_TIME_HEADER, SDK_VERSION_HEADER } from "../../../config"

export class SdkHeaderInterceptor implements Transport {
  constructor(private readonly sdk: Sdk, private readonly clock: Clock, private readonly delegate: Transport) {}

  send(request: TransportRequest): Promise<TransportResponse> {
    const newRequest = request
      .toBuilder()
      .addHeader(SDK_KEY_HEADER, this.sdk.key)
      .addHeader(SDK_NAME_HEADER, this.sdk.name)
      .addHeader(SDK_VERSION_HEADER, this.sdk.version)
      .addHeader(SDK_TIME_HEADER, this.clock.currentMillis().toString())
      .build()
    return this.delegate.send(newRequest)
  }
}
