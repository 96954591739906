import WorkspaceFetcher from "./WorkspaceFetcher"
import { Synchronizer } from "../sync/Synchronizer"
import Workspace from "./Workspace"
import { HttpWorkspaceFetcher } from "./HttpWorkspaceFetcher"
import Logger from "../logger"
import { WorkspaceConfig, WorkspaceConfigStorage } from "./WorkspaceConfig"

export class WorkspaceManager implements WorkspaceFetcher, Synchronizer {
  private workspace: Workspace | null = null
  private lastModified: string | null = null

  constructor(
    private readonly workspaceConfigStorage: WorkspaceConfigStorage,
    private readonly workspaceFetcher: HttpWorkspaceFetcher
  ) {
    const config = this.workspaceConfigStorage.get()
    if (config) {
      this.setConfig(config)
    }
  }

  private setConfig(config: WorkspaceConfig) {
    this.setWorkspace(config)
    this.lastModified = config.lastModified
  }

  private setWorkspace(config: WorkspaceConfig) {
    try {
      this.workspace = Workspace.from(config.config)
    } catch (e) {
      Logger.log.error(`Failed to set workspace: ${e}`)
    }
  }

  get(): Workspace | null {
    return this.workspace
  }

  async sync(): Promise<void> {
    try {
      const config = await this.workspaceFetcher.fetchIfModified(this.lastModified)
      if (config) {
        this.setConfig(config)
        this.workspaceConfigStorage.set(config)
      }
    } catch (e) {
      Logger.log.error(`Failed to sync workspace: ${e}`)
    }
  }

  async close(): Promise<void> {}
}
