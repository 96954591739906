import { MatchType, TargetMatch } from "../../model/model"
import OperatorMatcher, { OperatorMatcherFactory } from "./OperatorMatcher"
import { ValueDecorator, NoopValueDecorator } from "./ValueDecorator"
import ValueMatcher, { ValueMatcherFactory } from "./ValueMatcher"

export default class ValueOperatorMatcher {
  constructor(
    private readonly valueMatcherFactory: ValueMatcherFactory,
    private readonly operatorMatcherFactory: OperatorMatcherFactory
  ) {}

  matches(userValue: any, match: TargetMatch, valueDecorator: ValueDecorator = new NoopValueDecorator()): boolean {
    const valueMatcher = this.valueMatcherFactory.getMatcher(match.valueType)
    const operatorMatcher = this.operatorMatcherFactory.getMatcher(match.operator)
    const isMatched = this.userValueMatches(userValue, match, valueMatcher, operatorMatcher, valueDecorator)
    return this.typeMatches(match.type, isMatched)
  }

  private userValueMatches(
    userValue: any,
    match: TargetMatch,
    valueMatcher: ValueMatcher,
    operatorMatcher: OperatorMatcher,
    valueDecorator: ValueDecorator
  ): boolean {
    if (Array.isArray(userValue)) {
      return this.arrayMatches(userValue, match, valueMatcher, operatorMatcher, valueDecorator)
    }
    return this.singleMatches(userValue, match, valueMatcher, operatorMatcher, valueDecorator)
  }

  private singleMatches(
    userValue: any,
    match: TargetMatch,
    valueMatcher: ValueMatcher,
    operatorMatcher: OperatorMatcher,
    valueDecorator: ValueDecorator
  ): boolean {
    return match.values.some((it) =>
      valueMatcher.matches(operatorMatcher, valueDecorator.decorate(userValue), valueDecorator.decorate(it))
    )
  }

  private arrayMatches(
    userValues: any[],
    match: TargetMatch,
    valueMatcher: ValueMatcher,
    operatorMatcher: OperatorMatcher,
    valueDecorator: ValueDecorator
  ): boolean {
    return userValues.some((it) => this.singleMatches(it, match, valueMatcher, operatorMatcher, valueDecorator))
  }

  private typeMatches(matchType: MatchType, isMatched: boolean): boolean {
    switch (matchType) {
      case "MATCH":
        return isMatched
      case "NOT_MATCH":
        return !isMatched
    }
  }
}
