import {
  Decision,
  DecisionReason,
  FeatureFlagDecision,
  HackleEvent,
  HackleRemoteConfig,
  Properties,
  sanitizeUser,
  User,
  VariationKey
} from "../core/internal/model/model"
import PropertyUtil from "../core/internal/util/PropertyUtil"
import IdentifierUtil from "../core/internal/util/IdentifierUtil"
import { PropertyOperations, PropertyOperationsBuilder } from "./property/PropertyOperations"
import { BrowserHackleClient, PageView } from "./index.browser"
import HackleWebAppRemoteConfig from "./remoteconfig/HackleWebAppRemoteConfig"
import { DefaultParameterConfig } from "../core/internal/config/ParameterConfig"
import InvocatorFactory from "../core/internal/invocator/InvocatorFactory"
import InvocationResponseResolver from "../core/internal/invocator/InvocationResponseResolver"
import Logger from "../core/internal/logger"
import { getUserId } from "../hackle/user/index.browser"
import {
  FeatureFlagDetailInvocation,
  FeatureFlagInvocationDto,
  GetSessionIdInvocation,
  GetUserInvocation,
  HackleAppInvocationProcessor,
  InvocationDecisionDto,
  InvocationFeatureFlagDecisionDto,
  IsFeatureOnInvocation,
  ResetUserInvocation,
  SetDeviceIdInvocation,
  SetUserIdInvocation,
  SetUserInvocation,
  SetUserPropertyInvocation,
  ShowUserExplorerInvocation,
  TrackInvocation,
  UpdateUserPropertiesInvocation,
  VariationDetailInvocation,
  VariationInvocation,
  VariationInvocationDto
} from "./invocator/HackleAppInvocationProcessor"

const log = Logger.log

export interface HackleApp {
  getAppSdkKey: () => string
  getInvocationType: () => string
  invoke?: (stringified: string) => string | null
}

declare global {
  interface Window {
    _hackleApp?: HackleApp
  }
}

export function resolveHackleWebAppClientOrNull(
  invocationType: string,
  invocatorFactory: InvocatorFactory
): BrowserHackleClient | null {
  const invocator = invocatorFactory.getInvocator(invocationType, new InvocationResponseResolver())

  if (invocator === null) {
    log.error(`Failed to get invocator with invocationType:${invocationType}`)
    return null
  }

  return new HackleWebAppClientImpl(new HackleAppInvocationProcessor(invocator))
}

export function isHackleWebApp(_window: Window): _window is Window & {
  _hackleApp: HackleApp
} {
  if (!_window || !("_hackleApp" in _window) || _window._hackleApp === null || typeof _window._hackleApp !== "object") {
    return false
  }

  if (!("getAppSdkKey" in _window._hackleApp) || typeof _window._hackleApp.getAppSdkKey !== "function") {
    return false
  }

  if (!("getInvocationType" in _window._hackleApp) || typeof _window._hackleApp.getInvocationType !== "function") {
    return false
  }

  return true
}

export class HackleWebAppClientImpl implements BrowserHackleClient {
  constructor(private readonly invocationProcessor: HackleAppInvocationProcessor) {
    // This is to prevent the "This Binding" problem.
    this.getSessionId = this.getSessionId.bind(this)
    this.getUser = this.getUser.bind(this)
    this.setUser = this.setUser.bind(this)
    this.setUserId = this.setUserId.bind(this)
    this.setDeviceId = this.setDeviceId.bind(this)
    this.setUserProperty = this.setUserProperty.bind(this)
    this.setUserProperties = this.setUserProperties.bind(this)
    this.updateUserProperties = this.updateUserProperties.bind(this)
    this.resetUser = this.resetUser.bind(this)
    this.variation = this.variation.bind(this)
    this.variationDetail = this.variationDetail.bind(this)
    this.isFeatureOn = this.isFeatureOn.bind(this)
    this.featureFlagDetail = this.featureFlagDetail.bind(this)
    this.track = this.track.bind(this)
    this.trackPageView = this.trackPageView.bind(this)
    this.remoteConfig = this.remoteConfig.bind(this)
    this.onReady = this.onReady.bind(this)
    this.onInitialized = this.onInitialized.bind(this)
    this.showUserExplorer = this.showUserExplorer.bind(this)
    this.hideUserExplorer = this.hideUserExplorer.bind(this)
    this.fetch = this.fetch.bind(this)
    this.close = this.close.bind(this)
  }

  getSessionId(): string {
    try {
      const getSessionIdInvocation: GetSessionIdInvocation = { command: "getSessionId" }
      const result = this.invocationProcessor.process<never, string>(getSessionIdInvocation)
      return result ?? getUserId()
    } catch (e) {
      log.error(`Unexpected exception while get sessionId: ${e}`)
      return getUserId()
    }
  }

  getUser(): User {
    try {
      const getUserInvocation: GetUserInvocation = { command: "getUser" }
      const result = this.invocationProcessor.process<never, User>(getUserInvocation)
      return sanitizeUser(result) ?? this.defaultUser()
    } catch (e) {
      log.error(`Unexpected exception while get user: ${e}`)
      return this.defaultUser()
    }
  }

  async setUser(user: User): Promise<void> {
    try {
      const sanitizedUser = sanitizeUser(user)
      if (!sanitizedUser) {
        log.warn("invalid user")
        return
      }

      const setUserInvocation: SetUserInvocation = { command: "setUser", parameters: { user: sanitizedUser } }
      this.invocationProcessor.process(setUserInvocation)
    } catch (e) {
      log.error(`Unexpected exception while set user: ${e}`)
    }
  }

  async setUserId(userId: string | number | undefined): Promise<void> {
    try {
      const sanitizedUserId = IdentifierUtil.sanitizeValue(userId)
      if (!sanitizedUserId) {
        log.warn(`Invalid userId. [userId=${userId}]`)
        return
      }

      const setUserIdInvocation: SetUserIdInvocation = { command: "setUserId", parameters: { userId: sanitizedUserId } }
      this.invocationProcessor.process(setUserIdInvocation)
    } catch (e) {
      log.error(`Unexpected exception while set userId: ${e}`)
    }
  }

  async setDeviceId(deviceId: string): Promise<void> {
    try {
      const sanitizedDeviceId = IdentifierUtil.sanitizeValue(deviceId)
      if (!sanitizedDeviceId) {
        log.warn(`Invalid deviceId. [deviceId=${deviceId}]`)
        return
      }

      const setDeviceIdInvocation: SetDeviceIdInvocation = {
        command: "setDeviceId",
        parameters: { deviceId: sanitizedDeviceId }
      }
      this.invocationProcessor.process(setDeviceIdInvocation)
    } catch (e) {
      log.error(`Unexpected exception while set deviceId: ${e}`)
    }
  }

  async setUserProperty(key: string, value: any): Promise<void> {
    try {
      const setUserPropertyInvocation: SetUserPropertyInvocation = {
        command: "setUserProperty",
        parameters: { key, value }
      }
      this.invocationProcessor.process(setUserPropertyInvocation)
    } catch (e) {
      log.error(`Unexpected exception while set userProperty: ${e}`)
    }
  }

  async setUserProperties(properties: Properties<string>): Promise<void> {
    try {
      const sanitizedProperties = PropertyUtil.sanitize(properties)
      const operations = new PropertyOperationsBuilder()

      Object.entries(sanitizedProperties).forEach(([key, value]) => {
        operations.set(key, value)
      })
      this.updateUserProperties(operations.build())
    } catch (e) {
      log.error(`Unexpected exception while set userProperties: ${e}`)
    }
  }

  async updateUserProperties(operations: PropertyOperations): Promise<void> {
    try {
      const updateUserPropertiesInvocation: UpdateUserPropertiesInvocation = {
        command: "updateUserProperties",
        parameters: { operations: operations.toRecord() }
      }
      this.invocationProcessor.process(updateUserPropertiesInvocation)
    } catch (e) {
      log.error(`Unexpected exception while update userProperties: ${e}`)
    }
  }

  async resetUser(): Promise<void> {
    try {
      const resetUserInvocation: ResetUserInvocation = { command: "resetUser" }
      this.invocationProcessor.process(resetUserInvocation)
    } catch (e) {
      log.error(`Unexpected exception while reset user: ${e}`)
    }
  }

  variation(experimentKey: number, user?: User | string, defaultVariation: VariationKey = "A"): string {
    try {
      const variationInvocation: VariationInvocation = {
        command: "variation",
        parameters: { experimentKey, defaultVariation, user: this.convertUser(user) }
      }

      const result = this.invocationProcessor.process<VariationInvocationDto, string>(variationInvocation)

      if (!result) {
        throw new Error("invoke result data not exists")
      }

      return result
    } catch (e) {
      log.error(
        `Unexpected exception while deciding variation for experiment[${experimentKey}]. Returning default variation[${defaultVariation}] : ${e}`
      )
      return defaultVariation
    }
  }

  variationDetail(experimentKey: number, user?: User | string, defaultVariation: VariationKey = "A"): Decision {
    try {
      const variationDetailInvocation: VariationDetailInvocation = {
        command: "variationDetail",
        parameters: { experimentKey, defaultVariation, user: this.convertUser(user) }
      }

      const result = this.invocationProcessor.process<VariationInvocationDto, InvocationDecisionDto>(
        variationDetailInvocation
      )

      if (result === null) {
        throw new Error("invoke result data not exists")
      }

      return Decision.of(
        result.variation,
        result.reason,
        new DefaultParameterConfig(new Map(Object.entries(result.config.parameters ?? {})))
      )
    } catch (e) {
      log.error(
        `Unexpected exception while deciding variation for experiment[${experimentKey}]. Returning default variation[${defaultVariation}] : ${e}`
      )
      return Decision.of(defaultVariation, DecisionReason.EXCEPTION)
    }
  }

  isFeatureOn(featureKey: number, user?: User | string): boolean {
    try {
      const isFeatureOnInvocation: IsFeatureOnInvocation = {
        command: "isFeatureOn",
        parameters: { featureKey, user: this.convertUser(user) }
      }

      const result = this.invocationProcessor.process<FeatureFlagInvocationDto, boolean>(isFeatureOnInvocation)

      if (result === null) {
        throw new Error("invoke result data not exists")
      }

      return Boolean(result)
    } catch (e) {
      log.error(
        `Unexpected exception while deciding feature flag[${featureKey}]. Returning default value[false] : ${e}`
      )
      return false
    }
  }

  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision {
    try {
      const featureFlagDetailInvocation: FeatureFlagDetailInvocation = {
        command: "featureFlagDetail",
        parameters: { featureKey, user: this.convertUser(user) }
      }

      const result = this.invocationProcessor.process<FeatureFlagInvocationDto, InvocationFeatureFlagDecisionDto>(
        featureFlagDetailInvocation
      )

      if (result === null) {
        throw new Error("invoke result data not exists")
      }

      return new FeatureFlagDecision(
        result.isOn,
        result.reason,
        new DefaultParameterConfig(new Map(Object.entries(result.config.parameters ?? {}))),
        undefined
      )
    } catch (e) {
      log.error(
        `Unexpected exception while deciding feature flag[${featureKey}]. Returning default value[false] : ${e}`
      )
      return FeatureFlagDecision.off(DecisionReason.EXCEPTION)
    }
  }

  track(event: HackleEvent | string, user?: User | string) {
    try {
      const trackInvocation: TrackInvocation = {
        command: "track",
        parameters: { event: this.convertEvent(event), user: this.convertUser(user) }
      }

      this.invocationProcessor.process(trackInvocation)
    } catch (e) {
      log.error(`Unexpected exception while tracking event: ${e}`)
    }
  }

  trackPageView(option?: PageView | undefined): void {
    log.info(`Web to app integration feature is not support 'trackPageView' method`)
  }

  remoteConfig(user?: string | User | undefined): HackleRemoteConfig {
    return new HackleWebAppRemoteConfig(this.invocationProcessor, this.convertUser(user))
  }

  onReady(block: () => void, timeout?: number | undefined): void {
    block()
  }

  showUserExplorer(): void {
    try {
      const showUserExplorerInvocation: ShowUserExplorerInvocation = { command: "showUserExplorer" }
      this.invocationProcessor.process(showUserExplorerInvocation)
    } catch (e) {
      log.error(`Unexpected exception while show userExplorer: ${e}`)
    }
  }

  hideUserExplorer(): void {
    log.info(`Web to app integration feature is not support 'hideUserExplorer' method`)
  }

  fetch(): Promise<void> {
    return Promise.resolve()
  }

  onInitialized(config?: { timeout?: number | undefined } | undefined): Promise<{ success: boolean }> {
    return Promise.resolve({ success: true })
  }

  close(): void {
    log.info(`Web to app integration feature is not support 'close' method`)
  }

  private convertEvent(event: HackleEvent | string): HackleEvent {
    if (typeof event === "string") {
      return { key: event }
    }
    return event
  }

  private convertUser(user?: User | string): User | string | undefined {
    if (typeof user === "string") return user
    if (!user) return undefined
    return sanitizeUser(user) ?? undefined
  }

  private defaultUser(): User {
    return {
      deviceId: getUserId()
    }
  }
}
