import { InvocationResponseDto } from "./HackleAppInvocator"

export default class InvocationResponseResolver {
  private isInvocatorResponse(response: unknown): response is InvocationResponseDto {
    return (
      typeof response === "object" &&
      response !== null &&
      "success" in response &&
      typeof (response as any).success === "boolean" &&
      "message" in response &&
      typeof (response as any).message === "string"
    )
  }

  resolve<R>(response: string | null): InvocationResponseDto<R> {
    const parsed = JSON.parse(response ?? "")

    if (!this.isInvocatorResponse(parsed)) {
      throw new Error("invalid invocator response data")
    }

    if (!parsed.success) {
      throw new Error("failed response")
    }

    const invocationResponseDto: InvocationResponseDto = {
      message: parsed.message,
      success: parsed.success,
      data: parsed.data
    }

    return invocationResponseDto
  }
}
